import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import SetIntervalMixin from '../../lib/mixins/SetIntervalMixin'
import style from './styles.sass';

let ConnectionStatus = React.createClass({
  mixins: [SetIntervalMixin],

  propTypes: {
    testUrl: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    onlineAgain: PropTypes.func
  },

  getInitialState() {
    return {
      online: true,
      secondsRemaining: 0
    }
  },

  componentDidMount() {
    this.setInterval(() => this.checkConnection(), 180000)
  },

  componentWillUnmount() {
    clearInterval(this.interval)
  },

  isOnline() {
    return this.state.online;
  },

  checkConnection() {
    return this.state.secondsRemaining <= 0 && this.connectionTest();
  },

  handleConnectionSuccess() {
    if (this.state.online == false) {
      this.backToOnline()
    }
    this.setState({online: true})
    this.setState({secondsRemaining: 0})
  },

  handleConnectionError() {
    this.setState({online: false})
    if (this.state.secondsRemaining == 0) {
      this.setState({secondsRemaining: 60})
      this.countDown()
    }
  },

  backToOnline() {
    this.props.onlineAgain()
  },

  renderMessage() {
    switch (this.props.lang) {
      case 'pl':
        return `Twoje urządzenie straciło połączenie z internetem. Ponawiam za ${this.state.secondsRemaining}s`;
      default:
        return `Your device lost its internet connection. Reconnecting in ${this.state.secondsRemaining}s`;
    }
  },

  connectionTest() {
    let config = {
      method: 'GET',
      credentials: 'same-origin',
      cache: 'default'
    }

    return fetch(this.props.testUrl, config)
      .then((response) => {
        if (response.ok) {
          this.handleConnectionSuccess();
          return true;
        } else {
          this.handleConnectionError();
          return false;
        }
      })
      .catch((error) => {
        this.handleConnectionError();
        console.log(`connection error: ${error}`);
        return false;
      });
  },

  countDown() {
    this.interval = setInterval(this.countDownTick, 1000);
  },

  countDownTick() {
    this.setState({secondsRemaining: this.state.secondsRemaining - 1});
    if (this.state.secondsRemaining <= 0) {
      clearInterval(this.interval);
      this.checkConnection()
    }
  },

  styles() {
    return [
      style.offline_ui,
      this.isOnline() ? style.offline_ui_up : style.offline_ui_down,
      (!this.isOnline() && style.offline_ui_connecting),
      (!this.isOnline() && style.offline_ui_waiting),
    ].join(' ');
  },

  render() {
    return (
      <div className={this.styles()}>
        <div className={style.offline_ui_content}>
          {this.renderMessage()}
        </div>
      </div>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
    supplies: state.supplies.supplies,
    selectedLocationId: state.locations.selectedId,
    selectedStage: state.supplies.selectedStage
  }
}

export default ConnectionStatus

import { carrierCancelOrder,
  carrierInformOrder,
  carrierTakeOrder,
  carrierDeliverOrder,
  qualityAcceptOrder,
  qualityRejectOrder,
  check } from './actions'

export function massCancelOrder(ids, reason) {
  return (dispatch, getState) => {
    ids.map(id => {
      return dispatch(carrierCancelOrder(id, reason));
    })
    dispatch(check(null));
  }
}

export function massInformOrder(ids, info) {
  return (dispatch, getState) => {
    ids.map(id => {
      return dispatch(carrierInformOrder(id, info));
    })
    dispatch(check(null));
  }
}

export function massTakeOrder(ids) {
  return (dispatch, getState) => {
    ids.map(id => {
      return dispatch(carrierTakeOrder(id));
    })
    dispatch(check(null));
  }
}

export function massDeliverOrder(ids) {
  return (dispatch, getState) => {
    ids.map(id => {
      return dispatch(carrierDeliverOrder(id));
    })
    dispatch(check(null));
  }
}

export function qualityMassAcceptOrder(ids) {
  return (dispatch, getState) => {
    ids.map(id => {
      return dispatch(qualityAcceptOrder(id));
    })
    dispatch(check(null));
  }
}

export function qualityMassRejectOrder(ids) {
  return (dispatch, getState) => {
    ids.map(id => {
      return dispatch(qualityRejectOrder(id));
    })
    dispatch(check(null));
  }
}

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import { Link } from 'react-router';

import SuppliesSubmitButton from './header/SuppliesSubmitButton'
import ThemeSwitch from '../../common/lib/ThemeSwitch';

let Header = React.createClass({

  title() {
    const { t, location } = this.props;

    if (/\/orders\/summary/.test(location.pathname)) {
      return (
        <span>
          <div className="fa fa-plus"> </div>
          { " " }{t('orderFinalization')}
        </span>
      )
    } else if (/\/supplies/.test(location.pathname)) {
      return (
        <span>
          <div className="fa fa-plus"> </div>
          { " " }{t('newOrder')}
          <span className="header-subtitle">&nbsp;{t('operatorView')}</span>
        </span>
      )
    } else if (/\/orders/.test(location.pathname)) {
      return (
        <span>
          <div className="fa fa-list"> </div>
          { " " }{t('ordersList')}
          <span className="header-subtitle">&nbsp;{t('operatorView')}</span>
        </span>
      )
    } else if (/\/profile/.test(location.pathname)) {
      return (
        <span>
          <div className="fa fa-list"> </div>
          { " " }{t('profile')}
        </span>
      )
    }
  },

  renderSuppliesLink() {
    if (this.props.location.pathname != '/supplies') {
      return (
        <li>
          <Link to={'/supplies'} className="switch-link">
            <i className="fa fa-plus"></i>
          </Link>
        </li>
      )
    }
  },

  renderOrdersLink() {
    if (this.props.location.pathname != '/orders') {
      return (
        <li>
          <Link to={'/orders'} className="switch-link">
            <i className="fa fa-list"></i>
          </Link>
        </li>
      )
    }
  },

  render() {
    const { t } = this.props;
    const { userName } = this.props.app;

    return (
      <header id="portable-header">
        <div className="content-wrapper">
          <div className="header-title operator-header-title">
            {this.title()}
          </div>
          <ul className="actions">
            <li><ThemeSwitch /></li>
            <li className="btn-item">
              <SuppliesSubmitButton router={this.props.location} />
            </li>
            {this.renderOrdersLink()}
            {this.renderSuppliesLink()}
            <li className="user-menu">
              <a style={{ userSelect: 'none' }}>
                <i className="fa fa-user"></i>&nbsp;
                <span className='user-name'>{userName}</span>
              </a>
              <ul>
                <li>
                  <Link to={'/profile'} className="switch-link">
                    {t('profile')}
                  </Link>
                </li>
                <li>
                  <a href="/users/sign_out">{this.props.t('common:signOut')}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </header>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    app: state.app,
    supplies: state.supplies.supplies
  }
}

export default connect(mapStateToProps)(translate(['header'])(Header))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {translate} from 'react-i18next'

import Button from '../../common/lib/Button'
import ConfirmButton from '../../common/lib/ConfirmButton'
import Modal from '../../common/lib/Modal'
import CancelOrder from '../orders/CancelOrder'
import InformOrder from '../orders/InformOrder'
import { setCurrentView } from '../../common/app/actions'
import { massInformOrder,
         massDeliverOrder,
         massTakeOrder } from '../../common/orders/massActions'


let OrdersMassActions = React.createClass({

  renderCancelButton() {
    return <span onClick={e => this.refs.cancelModal.showModal()}>
      <Button className="btn btn-danger">
        {this.props.t('orders:cancel')}
      </Button>
      <Modal refName='cancelModal' ref='cancelModal'>
        <CancelOrder orderIds={this.props.checkedOrders}/>
      </Modal>
    </span>;
  },

  renderInformButton() {
    return <span onClick={e => this.refs.infosModal.showModal()}>
      <Button className="btn btn-info">
        <i className="fa fa-info-circle"></i>&nbsp;
        {this.props.t('orders:inform')}
      </Button>
      <Modal refName='infosModal' ref='infosModal'>
        <InformOrder orderIds={this.props.checkedOrders}/>
      </Modal>
    </span>;
  },

  handleOrderInfrom() {
    this.props.dispatch(massInformOrder(this.props.checkedOrders));
  },

  renderTakeButton() {
    return <span>
      <ConfirmButton
        type="button"
        onClick={e => this.handleOrderTake(e)}
        color="inform"
        className='btn btn-success'
      >
        <i className="fa fa-check"></i>&nbsp;
        {this.props.t('orders:take')}
      </ConfirmButton>
    </span>;
  },

  handleOrderTake() {
    this.props.dispatch(massTakeOrder(this.props.checkedOrders));
  },

  renderDeliveryButton() {
    return <span>
      <ConfirmButton
        type="button"
        onClick={e => this.handleOrderDelivery(e)}
        color="inform"
        className='btn btn-success'
      >
        <i className="fa fa-check"></i>&nbsp;
        {this.props.t('orders:deliver')}
      </ConfirmButton>
    </span>;
  },

  handleOrderDelivery() {
    this.props.dispatch(massDeliverOrder(this.props.checkedOrders));
  },

  render() {
    let anyCheckedOrders = this.props.checkedOrders.length != 0;
    let checkedOrdersStatus = this.props.checkedOrdersStatus;
    const { t } = this.props;

    if (!anyCheckedOrders) {
      return null;
    }

    return (
      <div className='float-box-wrapper'>
        <div className='float-box'>
          <ReactCSSTransitionGroup
            transitionName="fade"
            transitionAppear={true}
            transitionAppearTimeout={2000}
            transitionEnterTimeout={2000}
            transitionLeaveTimeout={100}
          >
          {anyCheckedOrders && this.renderInformButton()}
          {anyCheckedOrders && checkedOrdersStatus != 'taken' && this.renderTakeButton()}
          {anyCheckedOrders && checkedOrdersStatus == 'taken' && this.renderDeliveryButton()}
          {anyCheckedOrders && this.renderCancelButton()}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    app: state.app,
    checkedOrders: state.orders.checkedOrders,
    checkedOrdersStatus: state.orders.checkedOrdersStatus
  }
}

export default connect(mapStateToProps)(translate(['header'])(OrdersMassActions))

// Basics
export const White = '#FFF'
export const Black = '#000'
export const red = '#C00'

// Greens
export const Turquoise = '#1ABC9C'
export const GreenSea = '#16A085'
export const Emerald = '#2ECC71'
export const Nephritis = '#27AE60'

// Blues
export const PeterRiver = '#3498DB'
export const BelizeHole = '#2980B9'
export const WetAsphalt = '#34495E'
export const MidnightBlue = '#2C3E50'
export const JordyBlue = '#89C4F4'
export const BlueGray = '#34495e'
export const BlueOrderLight = '#4F6F8F'
export const BlueOrderDark = '#46637F'
export const FountainBlue = '#5C97BF'

// Purples
export const Amethyst = '#9B59B6'
export const Wisteria = '#8E44AD'

// Yellows
export const SunFlower = '#F1C40F'
export const CreamCan = '#F5D76E'

// Oranges
export const Orange = '#F39C12'
export const Carrot = '#E67E22'
export const Pumpkin = '#D35400'

// Reds
export const Alizarin = '#E74C3C'
export const Pomegranate = '#C0392B'

// Grays
export const Porcelain = '#ECF0F1'
export const Clouds = '#ECF0F1'
export const Silver = '#BDC3C7'
export const Concrete = '#95A5A6'
export const Asbestos = '#7F8C8D'

// Status
export const Done = Turquoise
export const New = Alizarin
export const Taken = SunFlower

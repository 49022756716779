/**
 * Calculates the center of a segment, returns an object {x, y }
 */
export default function getSegmentMidpoint(a, b) {
  let { x: x1, y: y1 } = a,
    { x: x2, y: y2 } = b

  return {
    x: (x1 + x2) / 2,
    y: (y1 + y2) / 2
  }
}

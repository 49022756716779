import {DropModal as Modal} from 'boron'
import React from 'react'
import Button from './Button'

let modalStyle = {
  background: '#4F6F8F',
  color: '#FFF'
};

let contentStyle = {
    height: '100%',
    maxWidth: '100%',
    wordWrap: 'break-word'
};


export default React.createClass({
  showModal: function() {
    this.refs[this.props.refName].show();
  },

  hideModal: function() {
    this.refs[this.props.refName].hide();
  },

  render: function() {
    return (
      <Modal ref={this.props.refName} className='modal' modalStyle={modalStyle} contentStyle={contentStyle} keyboard={true}>
        <div>
          {this.props.children}
        </div>

        <Button color='blue' onClick={this.hideModal}>Anuluj</Button>
      </Modal>
    );
  }

});

import React, { PropTypes } from 'react'

import {
  getDisplacement,
  getSegmentsRatio,
  getSegmentMidpoint
} from '../calculations'
import PositionMixin from '../../mixins/PositionMixin'
import propTypes from './propTypes'

const Touch = React.createClass({
  mixins: [PositionMixin],

  lastTouch: null,
  lastPinch: null,

  propTypes: {
    ...propTypes,
    onTap: PropTypes.func
  },

  getDefaultProps() {
    return {
      onTap: e => {},
      onRelease: e => {}
    }
  },

  handleTouchStart(e) {
    e.preventDefault()
    if (e.touches.length == 1) {
      this.lastTouch = this.getEventPosition(e.touches[0])
    } else if (e.touches.length == 2) {
      delete this.lastTouch
      this.lastPinch = this.getTouchListPositions(e.touches)
    }
  },

  handleTouchMove(e) {
    let newPosition, displacement, touchCount = e.touches.length
    e.preventDefault()

    if (this.lastTouch !== null && touchCount == 1) {
      this.handleDrag(e)
    } else if (this.lastPinch !== null && touchCount == 2) {
      this.handlePinch(e)
    }
    this.didMove = true
  },

  handleTouchEnd(e) {
    e.preventDefault()
    if (this.didMove !== true) {
      this.props.onTap(e, this.lastTouch)
    } else {
      this.props.onRelease(e)
    }
    delete this.lastTouch
    delete this.didMove
  },

  handleTouchCancel(e) {
    this.handleTouchEnd()
  },

  handleDrag(e) {
    let newPosition = this.getEventPosition(e.touches[0])
    let displacement = getDisplacement(this.lastTouch, newPosition, this.props.zoom)
    this.props.onPan(displacement)
    this.lastTouch = newPosition
  },

  handlePinch(e) {
    let newPinch = this.getTouchListPositions(e.touches)
    let pinchChangeRatio = getSegmentsRatio(this.lastPinch, newPinch)
    let factor = 1 - pinchChangeRatio
    let focal = getSegmentMidpoint(...newPinch)
    this.props.onZoom(focal, factor)
    this.lastPinch = newPinch
  },

  render () {
    return (
      <div
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        onTouchCancel={this.handleTouchCancel}
      >
        {this.props.children}
      </div>
    )
  }
})

export default Touch

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import Barcode from 'react-barcode'
import ReactDOM from 'react-dom'
import {translate} from 'react-i18next'
import moment from 'moment'

import ZoomedMap from '../../common/lib/map/ZoomedMap'
import MapContainer from '../../common/lib/map/Container'
import CancelOrder from './CancelOrder'
import Button from '../../common/lib/Button'
import Modal from '../../common/lib/Modal'
import { DATETIME_FORMAT, DEFAULT_BAR_FORMAT } from '../../common/variables'
import { operatorReorder, showDetails, operatorCancelOrder, operatorReturnOrder } from '../../common/orders/actions'

const formatDatetime = (datetime) => {
  if (!datetime) {
    return '-';
  }

  return moment(datetime).format(DATETIME_FORMAT);
};

let OrderDetails = React.createClass({
  getInitialState() {
    return {
      isOrderReturning: false,
      quantityToReturn: this.props.order.quantity_from_stock - this.props.order.returned_quantity,
      properQuantityToReturn: this.isProperQuantityToReturn(
        this.props.order.quantity_from_stock- this.props.order.returned_quantity,
        this.props.order.quantity_from_stock,
        this.props.order.returned_quantity
      ),
    };
  },

  componentDidUpdate() {
    let scrollableDiv = ReactDOM.findDOMNode(this).parentNode;
    scrollableDiv.scrollTop = 0;
  },

  componentWillReceiveProps(nextProps) {
    const nextQuantity = nextProps.order.quantity_from_stock - nextProps.order.returned_quantity

    this.setState({
      quantityToReturn: nextQuantity,
      properQuantityToReturn: this.isProperQuantityToReturn(
        nextQuantity,
        nextProps.order.quantity_from_stock,
        nextProps.order.returned_quantity
      ),
    });
},

  handleOrderCancel() {
    this.props.dispatch(operatorCancelOrder(this.props.order.id));
  },

  handleHideDetails() {
    this.props.dispatch(showDetails(null));
  },

  handleReorder() {
    this.props.scrollListUp();
    this.props.dispatch(showDetails(null));
    this.props.dispatch(operatorReorder(this.props.order.id));
  },

  handleReturnToStockQuantityChange(e) {
    const value = parseInt(e.target.value)
    const valid = this.isProperQuantityToReturn(value, this.props.order.quantity_from_stock, this.props.order.returned_quantity)

    this.setState({
      properQuantityToReturn: valid,
      quantityToReturn: value,
    });
  },

  isProperQuantityToReturn(value, orderQuantity, orderReturnedQuantity) {
    return 0 < value && value <= orderQuantity - orderReturnedQuantity
  },

  handleReturnToStock() {
    if (this.state.properQuantityToReturn) {
      this.setState({ isOrderReturning: true })
      this.props.dispatch(operatorReturnOrder(this.props.order.id, this.state.quantityToReturn)).then(
        () => {
          this.setState({ isOrderReturning: false })
        }
      )
    }
  },

  renderCancelButton() {
    if(['new', 'accepted'].includes(this.props.order.status)) {
      // Cancelling by operator causes destroying order!

      // return <div>
      //   <Button
      //     color='danger'
      //     onClick={e => this.refs.cancelModal.showModal()}
      //   >
      //     {this.props.t('common:cancel')}
      //   </Button>
      //   <Modal refName='cancelModal' ref='cancelModal'>
      //     <CancelOrder order={this.props.order}/>
      //   </Modal>
      // </div>;
        return <Button
          color='danger'
          onClick={() => this.props.dispatch(operatorCancelOrder(this.props.order.id))}
        >
          {this.props.t('common:cancel')}
        </Button>;
    }
  },

  renderReorderButton() {
    return <Button
      color='blue'
      onClick={this.handleReorder}>
      {this.props.t('reorder')}
    </Button>;
  },

  renderRemarks() {
    if (this.props.order.remarks) {
      return (
        <div className="order-more-remarks">
          <b>{this.props.t('remarks')}</b>
          {this.props.order.remarks}
        </div>
      )
    }

    return null;
  },

  renderReturnToStock() {
    if (
      this.props.order.status == "delivered" &&
        this.props.order.item.stock_quantity != -1 &&
        this.props.order.quantity_from_stock > 0
    ) {
      return (
        <div style={{ display: "inline-block" }}>
          {this.props.order.returned_quantity != this.props.order.quantity_from_stock && (
            <div style={{ display: "flex" }}>
              <input
                type="number"
                style={{
                  width: "50px",
                  height: "45px",
                  border: "1px solid #34495e",
                  textAlign: "center",
                }}
                value={this.state.quantityToReturn}
                onChange={this.handleReturnToStockQuantityChange}
                min={0}
                max={
                  this.props.order.quantity_from_stock - this.props.order.returned_quantity
                }
              />
              <Button
                color="blue"
                onClick={this.handleReturnToStock}
                disabled={
                  !this.state.properQuantityToReturn &&
                  !this.state.isOrderReturning
                }
              >
                {this.state.isOrderReturning ? (
                  <span className="fa fa-spinner fa-spin" />
                ) : (
                  this.props.t("returnToStock")
                )}
              </Button>{" "}
            </div>
          )}
          <div style={{ fontSize: "0.6em" }}>
            {this.props.order.returned_quantity > 0 &&
              this.props.order.returned_quantity !=
                this.props.order.quantity_from_stock && (
                <div>
                  {this.props.t("returned")}:{" "}
                  {this.props.order.returned_quantity}
                </div>
              )}
            {this.props.order.returned_quantity ==
              this.props.order.quantity_from_stock && (
              <div>{this.props.t("returnedAll")}</div>
            )}
          </div>
        </div>
      );
    }
  },

  render() {
    let { order, t } = this.props

    return (
      <div className="order-container order-more">
        <div className="order-more-desc">
          {order.item.description}
        </div>
        {order.item.stock_quantity == -1
          ? ''
          : (
            <div className="smaller">
              {t("supplies:stock_quantity")}: {order.item.stock_quantity}
            </div>
          )
        }
        <div className="order-actions">
          {this.renderReturnToStock()}
          {this.renderReorderButton()}
          {this.renderCancelButton()}
        </div>
        {order.item.image_medium_url &&
          <div className="center">
            <img src={order.item.image_medium_url} className="order-image" />
          </div>
        }
        {this.renderRemarks()}
        <div className="order-more-map mt30">
          <MapContainer style={{ height: 300 }}>
            <ZoomedMap
              image={{
                url: order.location.map_url,
                width: order.location.map_width,
                height: order.location.map_height
              }}
              positions={[this.props.order.spot]}
              selectedPositionId={this.props.order.spot.id}
              zoomId={this.props.order.id}
            />
          </MapContainer>
        </div>
        <div className="order-more-details mt30">
          <div className="order-more-location-barcode">
            <div className="location-barcode-title">
              {t('locationCode')}
            </div>
            <div className="location-barcode-code">
              <Barcode
                value={order.location.symbol}
                format={DEFAULT_BAR_FORMAT}
              />
            </div>
          </div>

          <div className="order-more-item-barcode">
            <div className="item-title-code">
              <div className="item-barcode-title">
                {t('productCode')}
              </div>
              <div className="item-barcode-code">
                <Barcode
                  value={order.item.code}
                  format={DEFAULT_BAR_FORMAT}
                  width={1}
                  fontSize={20}
                />
              </div>
            </div>
          </div>
        </div>
        <table className="order-more-timestamps">
          <tbody>
            <tr>
              <td>{t('createdAt')}</td>
              <td>{formatDatetime(order.created_at)}</td>
            </tr>
            <tr>
              <td>{t('takenAt')}</td>
              <td>{formatDatetime(order.taken_at)}</td>
            </tr>
            <tr>
              <td>{t('deliveredAt')}</td>
              <td>{formatDatetime(order.delivered_at)}</td>
            </tr>
            {
              order.location.delivery_confirmation && (
                <tr>
                  <td>{t('deliveryConfirmedAt')}</td>
                  <td>{formatDatetime(order.delivery_confirmed_at)}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    order: state.orders.orders.filter(function(elem) {
      return elem.id == state.orders.showDetailsOrderId;
    })[0]
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrderDetails))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {translate} from 'react-i18next'

import { qualityMassAcceptOrder, qualityMassRejectOrder } from '../../common/orders/massActions'


let OrdersMassActions = React.createClass({

  renderRejectButton() {
    return <span onClick={this.handleOrderReject}>
      <a className="btn btn-danger" href="#">
        <i className="fa fa-times"></i>
        &nbsp;{this.props.t('orders:reject')}
      </a>
  </span>;
  },

  handleOrderReject() {
    this.props.dispatch(qualityMassRejectOrder(this.props.checkedOrders));
  },

  renderAcceptButton() {
    return <span onClick={this.handleOrderAccept}>
      <a className="btn btn-success" href="#">
        <i className="fa fa-check"></i>
        &nbsp;{this.props.t('orders:accept')}
      </a>
    </span>;
  },

  handleOrderAccept() {
    this.props.dispatch(qualityMassAcceptOrder(this.props.checkedOrders));
  },

  render() {
    let anyCheckedOrders = this.props.checkedOrders.length != 0;
    const { t } = this.props;

    if (!anyCheckedOrders) {
      return null;
    }

    return (
      <div className='float-box-wrapper'>
        <div className='float-box'>
          <ReactCSSTransitionGroup
            transitionName="fade"
            transitionAppear={true}
            transitionAppearTimeout={2000}
            transitionEnterTimeout={2000}
            transitionLeaveTimeout={100}
          >
            {anyCheckedOrders && this.renderAcceptButton()}
            {anyCheckedOrders && this.renderRejectButton()}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    app: state.app,
    checkedOrders: state.orders.checkedOrders,
  }
}

export default connect(mapStateToProps)(translate(['header'])(OrdersMassActions))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import { setLocation } from '../../common/locations/actions'
import { setCategory, setStage } from '../../common/supplies/actions'
import { supplyStages } from '../../common/variables'

let LocationSelect = React.createClass({

  handleLocationChange(e) {
    this.props.dispatch(setLocation(e.target.value));
  },

  handleStageChange(e) {
    this.props.dispatch(setStage(e.target.value));
  },

  handleCategoryChange(e) {
    this.props.dispatch(setCategory(e.target.value));
  },

  sortedLocations() {
    const uniqueLocations = []
    this.props.locations.map(location => {
      if (!uniqueLocations.map(location => location.id).includes(location.id)) {
        uniqueLocations.push(location)
      }
    })

    return uniqueLocations.sort((a, b) => {
      const REGEX = /\d+/;
      let first = parseInt(REGEX.exec(a.symbol));
      let second = parseInt(REGEX.exec(b.symbol));

      return (first < second) ? -1 : (first > second) ? 1 : 0;
    });
  },

  countSuppliesForStage(stage) {
    if ( stage ==  'special_orders' ) { return 1 }

    return this.props.supplies.filter((supply) => {
      return supply.item.stage == stage;
    }).length;
  },

  operatorSupplyStages() {
    return [...supplyStages, 'special_orders'];
  },

  renderStageSelect() {
    const { t } = this.props;

    return (
      <select
        name="stage_id"
        id="select-supply-stage"
        onChange={(e) => this.handleStageChange(e)}
        tabIndex="-1"
        className=""
        value={this.props.selectedStage || 0}
      >
        {this.operatorSupplyStages().map((stage) => {
          if (this.countSuppliesForStage(stage) == 0) { return null };
          return <option
            key={stage}
            value={stage}
          >
            {t(`supplies:${stage}`)}
          </option>;
        })}
      </select>
    );
  },

  renderCategorySelect() {
    return (
      <select
        name="category_id"
        id="select-supply-category"
        onChange={(e) => this.handleCategoryChange(e)}
        tabIndex="-1"
        className=""
        value={this.props.selectedCategoryId || 0}
      >
        <option value="">
          {this.props.t('supplies:all_categories')}
        </option>
        {this.props.categories.map((category) => {
          return <option
            key={category.id}
            value={category.id}
          >
            {category.name}
          </option>;
        })}
      </select>
    );
  },

  render() {
    const { t } = this.props;

    return (
      <div className="location-select">
        <select
          name="location_id" id="select-location"
          onChange={(e) => this.handleLocationChange(e)}
          tabIndex="-1"
          className=""
          value={this.props.selectedLocationId || 0}
        >
          {this.sortedLocations().map((location) => {
            return <option
              key={location.id}
              value={location.id}
            >
              {location.symbol}
            </option>
          })}
        </select>
        {this.renderStageSelect()}
        {
          this.props.categories && this.props.categories.length > 0 && this.renderCategorySelect()
        }
      </div>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
    supplies: state.supplies.supplies,
    categories: state.supplies.categories,
    selectedLocationId: state.locations.selectedId,
    selectedStage: state.supplies.selectedStage,
    selectedCategoryId: state.supplies.selectedCategoryId
  }
}

export default connect(mapStateToProps)(translate(['header'])(LocationSelect))

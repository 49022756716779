/**
 * Shows the whole map.
 * Arguments: Map props
 * Returns: object with zoom and pan
 */
export default function zoomToFit({ image, containerWidth, containerHeight }) {
  let zoom, heightRatio, widthRatio, x = 0, y = 0

  if (containerHeight < image.height || containerWidth < image.width) {
    heightRatio = containerHeight / image.height
    widthRatio = containerWidth / image.width
    zoom = Math.min(heightRatio, widthRatio)

    x = - (containerWidth / zoom - image.width) / 2
    y = - (containerHeight / zoom - image.height) / 2

    return({
      zoom,
      pan: {
        x,
        y
      }
    })
  }
}

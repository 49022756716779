import { getFetchConfig, patchFetchConfig, postFetchConfig, parseJSON,
         checkStatus, deleteFetchConfig } from '../../common/fetchHelper'

import Promise from 'bluebird'
if (!window.Promise) {
  window.Promise = Promise;
}

function fetchSuccess(locations) {
  return { type: 'FETCH_LOCATIONS_SUCCESS', data: locations }
}

function fetchFailure(errors) {
  return { type: 'FETCH_LOCATIONS_FAILURE', errors }
}

export function setLocation(id) {
  return { type: 'SET_SELECTED_LOCATION', data: id }
}

export function updateSpot(spot) {
  return { type: 'UPDATE_SPOT', data: spot }
}

export function addSpot(spot) {
  return { type: 'ADD_SPOT', data: spot }
}

export function fetchLocations() {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/locations.json'

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
          dispatch(fetchSuccess(data));
          dispatch(setLocation(data[0]['id']));
          return data;
        })
      .catch((error) => {
        return;
      })
  }
}

export function fetchDisplayLocations() {
  return (dispatch, getState) => {
    let url = '/api/v1/display/locations.json'

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
          dispatch(fetchSuccess(data));
          return data;
        })
      .catch((error) => {
        return;
      })
  }
}

export function adminFetch(id) {
  return (dispatch, getState) => {
    let url = `/api/v1/admin/locations/${id}.json`

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        dispatch(fetchSuccess(res));
        return res;
      }, (error) => {
        dispatch(fetchFailure(error));
        throw error;
      });
  }
}

export function adminUpdatePostSpot(spot) {
  return (dispatch, getState) => {
    dispatch(updateSpot(spot));

    let url = `/api/v1/admin/spots/${spot.id}.json`
    let parameters = patchFetchConfig
    parameters['body'] = JSON.stringify({spot: spot})

    return fetch(url, parameters)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return;
      });
  }
}

export function adminDeleteSpot(spot) {
  return (dispatch, getState) => {

    let url = `/api/v1/admin/spots/${spot.id}.json`
    let parameters = deleteFetchConfig
    parameters['body'] = JSON.stringify({
      id: spot.id
    })

    return fetch(url, parameters)
      .then(res=>res.json())
      .then((res) => {

        if (res && res.errors && res.errors.base) {
          alert(res.errors.base[0]);
        }

        return res;
      })
      .catch((error) => {
        return;
      });
  }
}

export function createPostSpot(spot) {
  return (dispatch, getState) => {
    dispatch(addSpot(spot));

    let url = `/api/v1/admin/spots.json`;
    let location_id = getState().locations.locations.id;
    let parameters = postFetchConfig;
    let spotCopy = {...spot};
    spotCopy.id = null;

    parameters['body'] = JSON.stringify({spot: {...spotCopy, location_id: location_id}})

    return fetch(url, parameters)
      .then((res) => {
        dispatch(adminFetch(document.getElementsByName('location_id')[0].dataset.id));
        return res;
      })
      .catch((error) => {
        return;
      });
  }
}

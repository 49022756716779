import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

let OrderDirectionIcon = React.createClass({
  propTypes: {
    order: PropTypes.object.isRequired
  },

  deliveryOrder() {
    return /Delivery/.test(this.props.order.type);
  },

  className() {
    let classes = [
      'fa',
      'fa-fw',
      'fa-rotate-270',
      this.deliveryOrder() ? 'fa-download' : 'fa-upload'
    ]

    return classes.join(' ');
  },

  render() {
    let { order } = this.props;

    return (
      <i className={this.className()}></i>
    );
  }

});

export default translate(['orders'])(OrderDirectionIcon)

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'

import FromNow from '../../common/lib/FromNow'
import SetIntervalMixin from '../../common/lib/mixins/SetIntervalMixin'
import OrderMixin from '../../common/lib/mixins/OrderMixin'
import { showDetails, check } from '../../common/orders/actions'
import OrderStatusIcon from '../../common/lib/OrderStatusIcon'
import OrderDirectionIcon from '../../common/lib/OrderDirectionIcon'

let Order = React.createClass({
  mixins: [SetIntervalMixin, OrderMixin],

  render() {
    let { order } = this.props;

    return (
      <tr className={this.rowClassNames()} onClick={this.handleShowDetails}>
          <td className='status'></td>
          <td className={"order-meta " + this.highlighted()}>
            <div className='line hw'>
              <div className="formatted-date">
                <OrderStatusIcon order={order} />
                <FromNow datetime={order.created_at} />
              </div>
              <div className=''>
                <OrderDirectionIcon order={order} />
                <span className="order-meta-spot">&nbsp;{order.location.symbol}:&nbsp;{order.spot_symbol}</span>
              </div>
            </div>
            <div className='line hw'>
              <div className="order-code">{order.code},</div>
              {this.quantity()}
            </div>
          </td>
          <td className="order-details">
          { this.checkBox()}
            <div className="order-description">
              {order.item.description}
            </div>
          </td>
      </tr>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    showDetailsOrderId: state.orders.showDetailsOrderId,
    checkedOrders: state.orders.checkedOrders,
    checkedOrdersStatus: state.orders.checkedOrdersStatus
  }
}

export default connect(mapStateToProps)(Order)

export function setCSRFToken(token) {
  return { type: 'SET_APP_CSRF_TOKEN', CSRFToken: token };
}

export function setCurrentView(view) {
  return { type: 'SET_CURRENT_VIEW', data: view };
}

export function setCurrentPlant(id) {
  return { type: 'SET_CURRENT_PLANT', data: id };
}

export function setCurrentUser(id) {
  return { type: 'SET_CURRENT_USER', data: id };
}

export function setLanguage(lang) {
  return { type: 'SET_LANGUAGE', data: lang };
}

export function setUserName(name) {
  return { type: 'SET_USER_NAME', data: name };
}

export function setUser(user) {
  return { type: 'SET_USER', data: user };
}

export function setAvailableLocales(langs) {
  return { type: 'SET_AVAILABLE_LOCALES', data: langs };
}

export function setUserLocations(locations) {
  return { type: 'SET_USER_LOCATIONS', data: locations };
}

export function setDisplayName(name) {
  return { type: 'SET_DISPLAY_NAME', data: name };
}

export function setTheme(name) {
  return { type: 'SET_THEME', data: name };
}

import React, { PropTypes } from 'react'

import { getDisplacement } from '../calculations'
import PositionMixin from '../../mixins/PositionMixin'
import propTypes from './propTypes'

// FIXME: Only drag with left mouse button

/**
 * Translates mouse events into onZoom and onPan for ZoomableMap
 */
const Mouse = React.createClass({
  mixins: [PositionMixin],

  propTypes: {
    ...propTypes,
    onClick: PropTypes.func
  },

  lastPosition: null,

  getInitialState() {
    return { dragging: false }
  },

  getDefaultProps() {
    return {
      onClick: e => {},
      onRelease: e => {}
    }
  },

  handleWheel(e) {
    e.preventDefault()
    let factor = Math.round(e.deltaY / 5) / 100
    this.props.onZoom(this.getEventPosition(e), factor)
  },

  handleMouseDown(e) {
    e.preventDefault()
    this.lastPosition = this.getEventPosition(e)
    this.setState({ dragging: true })
  },

  handleMouseMove(e) {
    let newPosition, displacement
    e.preventDefault()

    if (this.lastPosition !== null) {
      newPosition = this.getEventPosition(e)
      displacement = getDisplacement(this.lastPosition, newPosition, this.props.zoom)
      this.props.onPan(displacement)
      this.lastPosition = newPosition
      this.didMove = true
    }
  },

  handleMouseLeave(e) {
    if (this.lastPosition !== null) {
      this.props.onRelease(e)
    }
    this.cleanUpAfterMove(e)
  },

  handleMouseUp(e) {
    if (this.lastPosition !== null) {
      if (this.didMove !== true) {
        this.props.onClick(e, this.lastPosition)
      } else {
        this.props.onRelease(e)
      }
    }

    this.cleanUpAfterMove(e)
  },

  cleanUpAfterMove(e) {
    e.preventDefault()
    this.setState({ dragging: false })
    delete this.lastPosition
    delete this.didMove
  },

  render () {
    return (
      <div
        onWheel={this.handleWheel}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        style={{ width: '100%', height: '100%', cursor: (this.state.dragging === true ? 'move' : '') }}
      >
        {this.props.children}
      </div>
    )
  }
})

export default Mouse

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import Order from './Order'

let OrdersList = React.createClass({

  renderTitle() {
    return this.props.location != undefined ? this.props.location.name : '';
  },

  renderOrdersCount() {
    const length = this.props.orders.length;


    if (length == 0) {
      return `: ${this.props.t('display:lack_of_orders')}`;
    } else if ( length == 1) {
      return `: ${length} ${this.props.t('orders:order.one')}`;
    } else {
      return `: ${length} ${this.props.t('orders:order.many')}`;
    }
  },

  render() {
    return (
      <div className='box-item'>
        <p className='box-title'>
          {this.renderTitle()}
          {this.renderOrdersCount()}
        </p>
        <table className="operator-orders" id="orders-list">
          <ReactCSSTransitionGroup
            component="tbody"
            transitionName="fade"
            component="tbody"
            transitionAppear={true}
            transitionAppearTimeout={2000}
            transitionEnterTimeout={3000}
            transitionLeaveTimeout={2500}
          >
            {this.props.orders.map(function(order, index) {
              return <Order
                key={order.id}
                index={index}
                updated_at={order.updated_at}
                order={order}
              />;
            })}
          </ReactCSSTransitionGroup>
        </table>
      </div>
    )
  }

})

export default translate(['display'])(OrdersList)

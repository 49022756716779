import React, { PropTypes } from 'react'
import Dimensions from 'react-dimensions'

import Position from './Position'

const Map = React.createClass({
  positionSize: 30,

  propTypes: {
    containerWidth: PropTypes.number.isRequired,
    containerHeight: PropTypes.number.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }).isRequired,
    positions: PropTypes.arrayOf(PropTypes.shape(Position.propTypes)).isRequired,
    selectedPositionId: PropTypes.number,
    onPositionClick: PropTypes.func,
    onPositionGrab: PropTypes.func,
    onPositionRelease: PropTypes.func,
    zoom: PropTypes.number,
    pan: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }),
    animate: PropTypes.bool
  },

  getDefaultProps() {
    return {
      zoom: 1,
      pan: {
        x: 0,
        y: 0
      },
      animate: false
    }
  },

  getViewBox() {
    let { containerWidth, containerHeight } = this.props
    return `0 0 ${containerWidth} ${containerHeight}`
  },

  getTranslate() {
    let { pan, zoom } = this.props
    let { x, y } = pan

    return `translate(${-x * zoom}px, ${-y * zoom}px)`
  },

  getScale() {
    return `scale(${this.props.zoom})`
  },

  getStyle() {
    return {
      width: '100%',
      height: '100%',
      background: '#000'
    }
  },

  render () {
    return (
      <svg
        style={this.getStyle()}
        viewBox={this.getViewBox()}
        preserveAspectRatio="xMinYMin slice"
        ref={svg => this.svg = svg}
        onWheel={this.props.onWheel}
        onMouseUp={this.props.onMouseUp}
        onMouseDown={this.props.onMouseDown}
        onMouseMove={this.props.onMouseMove}
        onMouseLeave={this.props.onMouseLeave}
        onTouchStart={this.props.onTouchStart}
        onTouchMove={this.props.onTouchMove}
        onTouchCancel={this.props.onTouchCancel}
        onTouchEnd={this.props.onTouchEnd}
      >
        <g
          style={{
            transform: `${this.getTranslate()} ${this.getScale()}`,
            transition: this.props.animate === true ? 'all 1s linear' : null
          }}
        >
          <image
            xlinkHref={this.props.image.url}
            height={this.props.image.height}
            width={this.props.image.width}
          />
        </g>
        <g
          style={{
            transform: this.getTranslate(),
            transition: this.props.animate === true ? 'all 1s linear' : null
          }}
        >
          {this.props.positions.map(position => {
            return (
              <Position
                key={position.id}
                {...position}
                size={this.positionSize}
                onClick={this.props.onPositionClick}
                onGrab={this.props.onPositionGrab}
                onRelease={this.props.onPositionRelease}
                zoom={this.props.zoom}
                isSelected={this.props.selectedPositionId == position.id}
                isGrabbed={this.props.grabbedPositionId == position.id}
                animate={this.props.animate}
              />
            )
          })}
        </g>
      </svg>
    )
  }
})

export default Dimensions()(Map)

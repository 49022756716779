export default(state = {
  supplies: [],
  categories: [],
  selectedStage: 'raw_material',
  selectedCategoryId: null,
  orderKind: 'delivery',
  qunatityModalSupply: null,
  quantityModal: null
}, action) => {
  switch (action.type) {

    case 'FETCH_SUPPLIES_SUCCESS':
      return {
        ...state,
        supplies: action.data
      }

    case 'FETCH_CATEGORIES_SUCCESS':
      return {
        ...state,
        categories: action.data
      }

    case 'SET_DEFAULT_SUPPLY_COUNT':
      return {
        ...state,
        supplies: state.supplies.map((supply) => {
            return {
              ...supply,
              count: 0
            }
          })
        }

    case 'INCREASE_SUPPLY_COUNT':
      let modalSupply = null;

      return {
        ...state,
        supplies: state.supplies.map((supply) => {
          if (supply.id == action.supply.id) {
            if (action.fixedCount) {
              return {
                ...supply,
                count: action.fixedCount
              }
            }

            let count;
            if (supply.count == 0) {
              count = supply.multiples[0];
            } else {
              let index = supply.multiples.indexOf(supply.count);
              count = supply.multiples[index + 1];
              count = count ? count : supply.multiples[index];

              if (count === '∞' || index == -1) {
                count = supply.count
                state.quantityModal.show()

                modalSupply = supply;
                return {
                  ...supply,
                  count: count,
                }
              }
            }

            return {
              ...supply,
              count: count
            }
          } else {
            return supply;
          }
        }),
        qunatityModalSupply: modalSupply
      }

    case 'OPEN_SUPPLY_MODAL':
      state.quantityModal.show();
      return state;

    case 'CLOSE_SUPPLY_MODAL':
      state.quantityModal.hide();
      return state;

    case 'SET_SUPPLY_MODAL':
      return {
        ...state,
        quantityModal: action.modal,
      }

    case 'DECREASE_SUPPLY_COUNT':
      return {
        ...state,
        supplies: state.supplies.map((supply) => {
          if (supply.id == action.supply.id) {

            let count;
            if (supply.count == 0) {
              count = 0
            } else {
              let index = supply.multiples.indexOf(supply.count);

              if (index == -1) {
                count = supply.multiples[supply.multiples.length - 2]
              } else {
                count = supply.multiples[index - 1];
                count = count ? count : 0;
              }
            }

            return {
              ...supply,
              count: count
            }
          } else {
            return supply;
          }
        })
      }

    case 'SET_SELECTED_STAGE':
      return {
        ...state,
        selectedStage: action.data
      }

    case 'SET_SELECTED_CATEGORY':
      const newCategoryId = action.data ? parseInt(action.data) : null;

      return {
        ...state,
        selectedCategoryId: newCategoryId
      }

    case 'SET_ORDER_DIRECTION':
      return {
        ...state,
        orderKind: action.data
      }

    case 'UPDATE_SUPPLY_ITEM_STOCK_QUANTITY':
      return {
        ...state,
        supplies: state.supplies.map((supply) => {
            if (supply.id == action.supplyId) {
              return { ...supply, item: { ...supply.item, stock_quantity: action.stockQuantity } }
            } else {
              return supply
            }
          })
        }

    default:
      return state
  }
}

import React, { PropTypes } from 'react'
import {connect} from 'react-redux'

import Header from './Header'
import Box from './Box'

import ConnectionStatus from '../../common/containers/connectionStatus/ConnectionStatus'

import {
  fetchDisplayLocations as fetchLocations
} from '../../common/locations/actions'
import { fetchDisplayOrders as fetchOrders } from '../../common/displayOrders/actions'

const App = React.createClass({
  componentWillMount() {
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchOrders());
  },

  filterOrdersByLocation(locationId) {
    return this.props.orders.filter((order) => {
      return order.location_id == locationId;
    });
  },

  onlineAgain() {
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchOrders());
  },

  styles() {
    return [
      'content',
      'box-container',
      `boxes-${this.props.app.locationIds.length}`
    ].join(' ')
  },

  render() {
    return (
      <div>
        <ConnectionStatus
          lang={this.props.app.language}
          testUrl={window.location.href}
          onlineAgain={this.onlineAgain}
        />
        <Header
          locationIds={this.props.app.locationIds}
        />
        <section className={this.styles()}>
          {this.props.app.locationIds.map((locationId) => {
            return <Box
              key={locationId}
              locationId={locationId}
            />;
          })}
        </section>
      </div>
    )
  }
})

let mapStateToProps = state => {
  return {
    app: state.app
  }
}

export default connect(mapStateToProps)(App)

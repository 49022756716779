import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import AdminMap from './AdminMap'
import AdminMapEditSpots from './AdminMapEditSpots'
import MapContainer from '../../common/lib/map/Container'
import EditSpot from './EditSpot'

const App = React.createClass({

  isEdition() {
    if(this.props.locations.locations.length == 0) {
      return false;
    } else {
      return true;
    }
  },

  render() {
    let { branch } = this.props.branches;
    let { t } = this.props;

    if(branch.map_url == '') {
      return null;
    }

    return (
      <div>
      { this.isEdition() &&
        <MapContainer style={{ height: 600 }}>
          <AdminMapEditSpots
            positions={this.props.locations.locations.spots}
            image={{
              url: branch.map_url,
              width: branch.map_width,
              height: branch.map_height
            }}/>
            <div className="actions-header">
              <h2>{t('title')}</h2>
            </div>
            <form>
              {this.props.locations.locations.spots.map((position, index) => {
                return <EditSpot spot={position} key={position.id}/>;
              })}
            </form>
          </MapContainer>
          }
        { !this.isEdition() &&
          <MapContainer style={{ height: 600 }}>
          <AdminMap
            positions={branch.spots}
            image={{
              url: branch.map_url,
              width: branch.map_width,
              height: branch.map_height
            }}
          />
          </MapContainer>
       }
       </div>
    )
  }
})

let mapStateToProps = state => {
  return {
    app: state.app,
    branches: state.branches,
    locations: state.locations
  }
}

export default connect(mapStateToProps)(translate(['locations'])(App))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

let Header = React.createClass({

  render() {
    const { t } = this.props;

    return (
      <header id="portable-header">
        <div className="content-wrapper">
          <div className="header-title operator-header-title">
            <span>
              <div className="fa fa-television"></div>
              {" "}{t('title')}: {this.props.app.displayName}
            </span>
          </div>
          <ul className="actions">
            <li className="user-menu">
              <img src='/assets/logo.png'></img>
            </li>
          </ul>
        </div>
      </header>
    )
  }

})

let mapStateToProps = (state, ownProps) => {
  return {
    app: state.app,
    locations: state.locations.locations.filter((location) => {
      return ownProps.locationIds.includes(location.id.toString());
    })
  }
}

export default connect(mapStateToProps)(translate(['display'])(Header))

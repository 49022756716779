import React from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import Button from '../../common/lib/Button'
import { operatorCancelOrder as cancelOrder } from '../../common/orders/actions'

let CancelOrder = React.createClass({

  cancelOrderClick(reason) {
    this.props.dispatch(cancelOrder(this.props.order.id, reason));
  },

  reasonLinks() {
    let reasons = this.props.cancelReasons;

    return reasons.map((reason) => {
      return <Button
        key={reason}
        color='danger'
        onClick={() => this.cancelOrderClick(reason)}>
        {this.props.t(`cancelReasons.${reason}`)}
      </Button>;
    });
  },

  render: function() {
    return (
      <div>
        <p className='center carrier-info-modal-content'>{this.props.t('cancelReason')}</p>
        {this.reasonLinks()}
      </div>
    );
  }

});

function mapStateToProps(state, ownProps) {
  return {
    cancelReasons: state.orders.cancelReasons
  }
}

export default connect(mapStateToProps)(translate(['orders'])(CancelOrder))

import React from 'react'
import moment from 'moment/moment.js'
import momentEN from 'moment/locale/en-gb'
import momentPl from 'moment/locale/pl'
import momentCS from 'moment/locale/cs'
import { showDetails, check } from '../../orders/actions'

export default {

  getInitialState() {
    return {
      isBlinking: false
    }
  },

  componentWillReceiveProps(nextProps) {
    if ( this.hasOrderChanged(nextProps) && this.state.isBlinking != true){
      this.shouldBlink();
    }
  },

  componentWillMount() {
    let diff = moment.duration(
      moment().diff(moment(this.props.order.created_at))
    ).asSeconds();

    if ( diff < 30 ){
      this.shouldBlink();
    }
  },

  shouldBlink() {
    this.setState({isBlinking: true});
    this.setInterval(() => this.setState({isBlinking: false}), 5000);
  },

  highlighted() {
    return this.props.order.id == this.props.showDetailsOrderId ? 'highlight' : '';
  },

  handleShowDetails() {
    if (this.props.order.id != this.props.showDetailsOrderId) {
      this.props.dispatch(showDetails(this.props.order.id));
    } else {
      this.props.dispatch(showDetails(null));
    }
  },

  hasOrderChanged(nextProps) {
    return (
      this.props.order != null
      && nextProps.order != null
      && this.props.order.updated_at != nextProps.order.updated_at
    );
  },

  rowClassNames() {
    let classes = [
      `order-container`,
      `order-box`,
      `order-${this.props.order.status}`,
      `order-${this.props.index % 2 == 0 ? "even" : "odd"}`,
      `${this.state.isBlinking ? "blink-me" : ""}`
    ]

    return classes.join(' ');
  },

  checkBox() {
    if (this.props.checkedOrders.length == 0 || this.props.order.status == this.props.checkedOrdersStatus) {
      return <div className={"square-box" + this.isChecked()} onClick={(e) => this.handleOrderCheck(e)}>
        <label></label>
        <input type="checkbox" />
      </div>;
    }
  },

  isChecked() {
    return this.props.checkedOrders.includes(this.props.order.id) ?  " checked" : '';
  },

  handleOrderCheck(e) {
    e.stopPropagation();
    this.props.dispatch(check(this.props.order.id));
  },

  quantity() {
    let batch_quantity = parseFloat(this.props.order.batch_quantity).toString();

    return (
      <div className="order-quantity">
        {this.props.order.quantity}
        &nbsp;x&nbsp;
        {batch_quantity}
        {this.props.order.item.unit}
      </div>
    )
  }
};

import { getFetchConfig, postFetchConfig, parseJSON, checkStatus } from '../../common/fetchHelper'

import Promise from 'bluebird'
if (!window.Promise) {
  window.Promise = Promise;
}

function fetchSuccess(orders) {
  return { type: 'FETCH_ORDERS_SUCCESS', data: orders }
}

function fetchFailure(errors) {
  return { type: 'FETCH_ORDERS_FAILURE', errors }
}

export function update(order) {
  return { type: 'UPDATE_ORDER', data: order }
}

export function fetchDisplayOrders() {
  return (dispatch, getState) => {
    let url = '/api/v1/display/orders.json'

    url = url + '?location_ids=' + encodeURIComponent(
      getState().app.locationIds
    );

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
          dispatch(fetchSuccess(data));
          return data;
        })
      .catch((error) => {
        dispatch(fetchFailure(error));
        return;
      });
  }
}

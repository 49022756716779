export default(state = {
  locations: [],
  selectedId: null
}, action) => {
  switch (action.type) {
    case 'FETCH_LOCATIONS_SUCCESS':
      return {
        ...state,
        locations: action.data
      }

    case 'SET_SELECTED_LOCATION':
      return {
        ...state,
        selectedId: action.data
      }

    case 'UPDATE_SPOT':
      let spots = state.locations.spots.map((spot) => {
        if (spot.id == action.data.id) {
          return { ...action.data }
        } else {
          return { ...spot }
        }
      });

      return {
        ...state,
        locations: {
          ...state.locations,
          spots: spots
        }
      }

    case 'ADD_SPOT':
      return {
        ...state,
        locations: {
          ...state.locations,
          spots: [
            ...state.locations.spots,
            action.data
          ]
        }
      }

    default:
      return state
  }
}

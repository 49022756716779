import { getFetchConfig, parseJSON, checkStatus } from '../../common/fetchHelper'

import Promise from 'bluebird'
if (!window.Promise) {
  window.Promise = Promise;
}

export function setDefaultCount() {
  return { type: 'SET_DEFAULT_SUPPLY_COUNT' }
}
export function IncreaseCount(supply) {
  return { type: 'INCREASE_SUPPLY_COUNT', supply: supply }
}

export function DecreaseCount(supply) {
  return { type: 'DECREASE_SUPPLY_COUNT', supply: supply }
}

function fetchSuccess(supplies) {
  return { type: 'FETCH_SUPPLIES_SUCCESS', data: supplies }
}

function categoriesFetchSuccess(categories) {
  return { type: 'FETCH_CATEGORIES_SUCCESS', data: categories }
}

function fetchFailure(errors) {
  return { type: 'FETCH_SUPPLIES_FAILURE', errors }
}

export function setStage(id) {
  return { type: 'SET_SELECTED_STAGE', data: id }
}

export function setCategory(id) {
  return { type: 'SET_SELECTED_CATEGORY', data: id }
}

export function setOrderDirection(direction) {
  return { type: 'SET_ORDER_DIRECTION', data: direction }
}

export function fetchSupplies(location_id) {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/locations/'+location_id+'/supplies.json'

    if (!location_id) {
      return [];
    }

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        dispatch(fetchSuccess(data));
        dispatch(setDefaultCount());
        return data;
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
        return;
      })
  }
}

export function fetchCategories() {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/categories.json'

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        dispatch(categoriesFetchSuccess(data));
        return data;
      })
      .catch((error) => {
        return [];
      })
  }
}

export function updateSupplyItemStockQuantity(supplyId, stockQuantity) {
  return { type: 'UPDATE_SUPPLY_ITEM_STOCK_QUANTITY', supplyId, stockQuantity }
}

import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import AdminMap from './AdminMap'
import AdminMapEditSpots from './AdminMapEditSpots'
import MapContainer from '../../common/lib/map/Container'

import { adminUpdatePostSpot, adminDeleteSpot } from '../../common/locations/actions'

const EditSpot = React.createClass({

  descriptionChange(e) {
    this.props.dispatch(adminUpdatePostSpot({...this.props.spot, description: e.target.value}));
  },

  symbolChange(e) {
    this.props.dispatch(adminUpdatePostSpot({...this.props.spot, symbol: e.target.value}));
  },

  characterChange(e) {
    e.stopPropagation();
    this.props.dispatch(adminUpdatePostSpot({...this.props.spot, character: e.target.value}));
  },

  formattedDescription() {
    return this.props.spot.description || '';
  },

  handleDelete(e, spot) {
    e.stopPropagation();
    this.props.dispatch(adminDeleteSpot(spot));
  },

  render() {
    let { spot, t } = this.props;

    if(spot == undefined) {
      return '';
    }

    return (
      <div className="row">
        <div className="col-2">
          <div className="input select optional location_spots_character">
            <label className="select optional">{t('character')}</label>
            <select onChange={(e) => this.characterChange(e)} value={spot.character} className="select optional">
              <option key='common' value="common">{t('characters.common')}</option>
              <option key='delivery' value="delivery">{t('characters.delivery')}</option>
              <option key='reception' value="reception">{t('characters.reception')}</option>
            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="input string optional location_spots_symbol">
            <label className="string optional">{t('symbol')}</label>
            <input onChange={(e) => this.symbolChange(e)} className="string optional" type="text" defaultValue={spot.symbol}></input>
          </div>
        </div>
        <div className="col-5">
          <div className="input string optional location_spots_description">
            <label className="string optional">{t('description')}</label>
            <input onChange={(e) => this.descriptionChange(e)} className="string optional" type="text" defaultValue={this.formattedDescription()}></input>
            </div>
          </div>
        <div className="col-2">
          <a href=''
            className='mt16 btn btn-danger'
            onClick={(e) => this.handleDelete(e, spot)}
          >
            {t('common:delete')}
          </a>
        </div>
      </div>
    )
  }
})

let mapStateToProps = state => {
  return {}
}

export default connect()(translate(['locations'])(EditSpot))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import Barcode from 'react-barcode'
import ReactDOM from 'react-dom'
import {translate} from 'react-i18next'

import Map from '../../common/lib/map/Map'
import ZoomedMap from '../../common/lib/map/ZoomedMap'
import MapContainer from '../../common/lib/map/Container'
import ConfirmButton from '../../common/lib/ConfirmButton'
import Button from '../../common/lib/Button'
import { DEFAULT_BAR_FORMAT } from '../../common/variables'
import { showDetails, qualityAcceptOrder, qualityRejectOrder } from '../../common/orders/actions'

let OrderDetails = React.createClass({

  componentDidUpdate() {
    let scrollableDiv = ReactDOM.findDOMNode(this).parentNode;
    scrollableDiv.scrollTop = 0;
  },

  handleHideDetails() {
    this.props.dispatch(showDetails(null));
  },

  renderRejectButton() {
    return  <ConfirmButton
      type="button"
      onClick={e => this.handleOrderReject(e)}
      color="danger"
    >
      {this.props.t('reject')}
    </ConfirmButton>;
  },

  handleOrderReject() {
    this.props.dispatch(qualityRejectOrder(this.props.order.id));
  },

  renderAcceptButton() {
    return  <ConfirmButton
      type="button"
      onClick={e => this.handleOrderAccept(e)}
      color="blue"
            >
      {this.props.t('accept')}
    </ConfirmButton>;
  },

  handleOrderAccept() {
    this.props.dispatch(qualityAcceptOrder(this.props.order.id));
  },

  render() {
    let { order, t } = this.props

    return (
      <div className="order-container order-more">
        <div className="order-more-desc">
          {order.item.description}
        </div>
        <div className="order-actions">
          {this.renderRejectButton()}
          {this.renderAcceptButton()}
        </div>
        <div className="order-more-map">
          <MapContainer style={{ height: 300 }}>
            <ZoomedMap
              image={{
                url: order.location.map_url,
                width: order.location.map_width,
                height: order.location.map_height
              }}
              positions={[order.spot]}
              selectedPositionId={order.spot.id}
              zoomId={order.id}
            />
          </MapContainer>
        </div>
        <div className="order-more-details">
          <div className="order-more-location-barcode">
            <div className="location-barcode-title">
              {t('productCode')}
            </div>
            <div className="location-barcode-code">
              <Barcode
                value={order.location.symbol}
                format={DEFAULT_BAR_FORMAT}
              />
            </div>
          </div>

          <div className="order-more-item-barcode">
            <div className="item-title-code">
              <div className="item-barcode-title">
                {t('locationCode')}
              </div>
              <div className="item-barcode-code">
                <Barcode
                  value={order.item.code}
                  format={DEFAULT_BAR_FORMAT}
                  width={1}
                  fontSize={20}
                />
              </div>
            </div>
          </div>
      </div>
      </div>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    order: state.orders.orders.filter(function(elem) {
      return elem.id == state.orders.showDetailsOrderId;
    })[0],
    currentUser: state.app.currentUserId
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrderDetails))

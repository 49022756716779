import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import OrderDetails from './OrderDetails'
import OrdersList from './OrdersList'
import Scanner from '../app/Scanner'

let OrdersWrapper = React.createClass({

  shouldShowDetails() {
    return !!this.props.showDetailsOrderId;
  },

  scrollListUp() {
    let scrollableDiv = this.refs.orderList;
    scrollableDiv.scrollTop = 0;
  },

  render() {
    return (
      <div className="content-wrapper">
        <div className="scanner-header">
          <Scanner />
        </div>
        <div ref='orderList' className={`scroll-wrapper ${this.shouldShowDetails() && 'half'}`}>
          <OrdersList/>
        </div>
        {
          this.shouldShowDetails() &&
          <div className='scroll-wrapper half'>
            <OrderDetails scrollListUp={this.scrollListUp}/>
          </div>
        }
      </div>
    )
  }

})

let mapStateToProps = state => {
  return {
    showDetailsOrderId: state.orders.showDetailsOrderId
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrdersWrapper))

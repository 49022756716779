/**
 * Returns Map props with updated zoom while keeping one point in the same place
 * relative to viewport.
 * Arguments: Map props, focal point, new zoom
 * Returns: object with zoom and pan
 */
export default function zoomToPoint(
  { zoom, pan, containerWidth, containerHeight },
  { x, y },
  targetZoom
) {
  let zoomChange = 1 - zoom/targetZoom

  return {
    zoom: targetZoom,
    pan: {
      x: pan.x + (x / zoom) * zoomChange,
      y: pan.y + (y / zoom) * zoomChange,
    }
  }
}

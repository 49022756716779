export default (
  state = {
    orders: [],
    isSendingOrders: false,
    showDetailsOrderId: false,
    remarks: '',
    checkedOrders: [],
    checkedOrdersStatus: '',
    cancelReasons: {},
    infos: {}
  },
  action
) => {
  switch (action.type) {

    case 'FETCH_ORDERS_SUCCESS':
      return {
        ...state,
        orders: action.data
      }

    case 'PLACE_ORDERS_REQUEST':
      return {
        ...state,
        isSendingOrders: true
      }

    case 'PLACE_ORDERS_SUCCESS':
      return {
        ...state,
        isSendingOrders: false
      }

    case 'UPDATE_ORDER':
      let orders = [];
      if (state.orders == []) {
        orders = [action.data];
      } else if (state.orders.map(order => order.id).includes(action.data.id)) {
        orders = state.orders.map((order) => {
          return order.id == action.data.id ? action.data : order
        });
      } else {
        orders = [action.data].concat(state.orders);
      }

      return {
        ...state,
        orders
      }

    case 'CHANGE_REMARKS':
      return {
        ...state,
        remarks: action.remarks
      }

    case 'SHOW_ORDER_DETAILS':
      return {
        ...state,
        showDetailsOrderId: action.id
      }

    case 'SET_ORDER_CANCEL_REASONS':
      return {
        ...state,
        cancelReasons: action.data
      }

    case 'SET_ORDER_INFOS':
      return {
        ...state,
        infos: action.data
      }

    case 'CHECK_ORDER':
      let checkedOrders = [...state.checkedOrders];
      let checkedOrdersStatus = '';

      if (action.id == null){
        checkedOrders = [];
      } else if (checkedOrders.includes(action.id)) {
        checkedOrders = checkedOrders.filter((elem) => {
          return elem != action.id;
        })
        checkedOrdersStatus = checkedOrders.length == '' ? '' : state.checkedOrdersStatus
      } else {
        checkedOrders = [...checkedOrders, action.id]
        checkedOrdersStatus = state.orders.filter(order =>
          order.id == checkedOrders[0])[0].status
      }
      return {
        ...state,
        checkedOrders: checkedOrders,
        checkedOrdersStatus: checkedOrdersStatus
      }
    default:
      return state
  }
}

import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import moment from 'moment/moment.js'
import momentEN from 'moment/locale/en-gb'
import momentPl from 'moment/locale/pl'
import momentCS from 'moment/locale/cs'

import SetIntervalMixin from './mixins/SetIntervalMixin'

let FromNow = React.createClass({
  mixins: [SetIntervalMixin],

  propTypes: {
    datetime: PropTypes.string.isRequired
  },

  componentDidMount() {
    this.setInterval(() => this.forceUpdate(), 15000)
  },

  sanitizedDatetime() {
    let { datetime } = this.props;
    return moment().isBefore(datetime) ? moment() : moment(datetime)
  },

  lang() {
    return this.props.language;
  },

  render () {
    return <span>{moment(this.sanitizedDatetime()).locale(this.lang()).fromNow()}</span>;
  }
})

function mapStateToProps(state, ownProps){
  return {
    language: state.app.language
  }
}

export default connect(mapStateToProps)(FromNow)

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'

import Header from './Header'
import OrdersWrapper from '../orders/OrdersWrapper'
import { fetchLocations } from '../../common/locations/actions'
import { qualityFetch as fetchOrders } from '../../common/orders/actions'
import ConnectionStatus from '../../common/containers/connectionStatus/ConnectionStatus'

let App = React.createClass({

  componentWillMount() {
    this.props.dispatch(fetchLocations());
  },

  currentView() {
    return <OrdersWrapper/>;
  },

  onlineAgain() {
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchOrders());
  },

  render() {
    return (
      <div>
        <ConnectionStatus
          lang={this.props.app.language}
          testUrl={window.location.href}
          onlineAgain={this.onlineAgain}
        />
        <Header/>
        <section className="content">
          {this.currentView()}
        </section>
      </div>

    )
  }

})

let mapStateToProps = state => {
  return {
    app: state.app,
    orders: state.orders,
  }
}

export default connect(mapStateToProps)(App)

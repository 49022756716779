import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import moment from 'moment/moment.js'
import momentEN from 'moment/locale/en-gb'
import momentPl from 'moment/locale/pl'
import momentCS from 'moment/locale/cs'

import OrdersList from '../orders/OrdersList'

let Box = React.createClass({
  render() {
    const { t, orders } = this.props;

    return (
      <OrdersList
        locationId={this.props.locationId}
        orders={this.props.orders}
        location={this.props.location}
      />
    );
  }

});

let mapStateToProps = (state, ownProps) => {
  return {
    orders: state.orders.orders.filter((order) => {
      return order.location_id == ownProps.locationId;
    }).sort((a,b) => {
      return moment.utc(b.updated_at).diff(moment.utc(a.updated_at));
    }),
    location: state.locations.locations.filter((location) => {
      return location.id == ownProps.locationId;
    })[0]
  }
}

export default connect(mapStateToProps)(translate(['orders'])(Box))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import moment from 'moment/moment.js'
import momentEN from 'moment/locale/en-gb'
import momentPl from 'moment/locale/pl'
import momentCS from 'moment/locale/cs'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import Order from './Order'

let OrdersList = React.createClass({

  filteredOrders() {
      return this.props.orders;
  },

  render() {
    return (
      <table className="operator-orders" id="orders-list">
        <ReactCSSTransitionGroup
          component="tbody"
          transitionName="fade"
          component="tbody"
          transitionAppear={true}
          transitionAppearTimeout={2000}
          transitionEnterTimeout={3000}
          transitionLeaveTimeout={2500}
        >
          {this.filteredOrders().map(function(order, index) {
            return <Order
              key={order.id}
              index={index}
              updated_at={order.updated_at}
              order={order}
            />;
          })}
        </ReactCSSTransitionGroup>
      </table>
    )
  }

})

let mapStateToProps = state => {
  return {
    orders: state.orders.orders.sort((a,b) => {
      return moment.utc(b.created_at).diff(moment.utc(a.created_at));
    })
  }
}

export default connect(mapStateToProps)(OrdersList)

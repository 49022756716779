import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import FromNow from '../../common/lib/FromNow'
import SetIntervalMixin from '../../common/lib/mixins/SetIntervalMixin'
import OrderMixin from '../../common/lib/mixins/OrderMixin'
import { operatorConfirmDelivery, showDetails } from '../../common/orders/actions'
import OrderStatusIcon from '../../common/lib/OrderStatusIcon'
import OrderDirectionIcon from '../../common/lib/OrderDirectionIcon'

let Order = React.createClass({
  mixins: [SetIntervalMixin, OrderMixin],

  handleConfirmDelivery(e) {
    e.stopPropagation();
    this.props.dispatch(operatorConfirmDelivery(this.props.order.id));
  },

  render() {
    let { order, t } = this.props;

    return (
      <tr className={this.rowClassNames()} onClick={this.handleShowDetails}>
          <td className='status'></td>
          <td className={"order-meta " + this.highlighted()}>
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='line'>
                  <div className="formatted-date">
                    <OrderStatusIcon order={order} />
                    <FromNow datetime={order.created_at} />
                  </div>
                  <div className=''>
                    <OrderDirectionIcon order={order} />
                    <span className="order-meta-spot">&nbsp;{order.location.symbol}:&nbsp;{order.spot_symbol}</span>
                  </div>
                </div>
                <div className='line ml6'>
                  <div className="order-code">{order.code},</div>
                  {this.quantity()}
                </div>
              </div>
              {
                order.status == 'delivered' && order.location.delivery_confirmation && !order.delivery_confirmed_at && (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <button className="btn btn-success" onClick={this.handleConfirmDelivery}>
                      {t('confirmDelivery')}
                    </button>
                  </div>
                )
              }
            </div>
          </td>
          <td className="order-details">
            {order.item.image_thumb_url &&
              <div className="order-thumb">
                <img src={order.item.image_thumb_url} className="order-image" />
              </div>
            }
            <div className="order-description">
              {order.item.description}
            </div>
          </td>
      </tr>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    showDetailsOrderId: state.orders.showDetailsOrderId
  }
}

export default connect(mapStateToProps)(translate(['orders'])(Order))

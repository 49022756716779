import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import { Link } from 'react-router';

import Button from '../../common/lib/Button'
import ConfirmButton from '../../common/lib/ConfirmButton'
import Modal from '../../common/lib/Modal'
import CancelOrder from '../orders/CancelOrder'
import InformOrder from '../orders/InformOrder'
import { setCurrentView } from '../../common/app/actions'
import { massInformOrder,
         massDeliverOrder,
         massTakeOrder } from '../../common/orders/massActions'
import ThemeSwitch from '../../common/lib/ThemeSwitch';

let Header = React.createClass({

  renderOrdersLink() {
    if (this.props.location.pathname != '/orders') {
      return (
        <li>
          <Link to={'/orders'} className="switch-link">
            <i className="fa fa-list"></i>
          </Link>
        </li>
      )
    }
  },

  render() {
    const { t } = this.props;
    const { userName } = this.props.app;

    return (
      <header id="portable-header">
        <div className="content-wrapper">
          <div className="header-title operator-header-title">
            <span>
              <div className="fa fa-bars"></div>
              {" "}{t('ordersList')}
              <span className="header-subtitle">&nbsp;{t('deliverView')}</span>
            </span>
          </div>
          <ul className="actions">
            <li><ThemeSwitch /></li>
            {this.renderOrdersLink()}
            <li className="user-menu">
              <a style={{ userSelect: 'none' }}>
                <i className="fa fa-user"></i>&nbsp;
                <span className='user-name'>{userName}</span>
              </a>
              <ul>
                <li>
                  <Link to={'/profile'} className="switch-link">
                    {t('profile')}
                  </Link>
                </li>
                <li>
                  <a href="/users/sign_out">{this.props.t('common:signOut')}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </header>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    app: state.app,
    checkedOrders: state.orders.checkedOrders,
    checkedOrdersStatus: state.orders.checkedOrdersStatus
  }
}

export default connect(mapStateToProps)(translate(['header'])(Header))

import React, { PropTypes }  from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import { Link } from 'react-router';

import Button from '../../../common/lib/Button'
import { setOrderDirection } from '../../../common/supplies/actions'

let SuppliesSubmitButton = React.createClass({

  propTypes: {
    router: PropTypes.object.isRequired
  },

  isDisabled() {
    return this.props.supplies.length <= 0;
  },

  disabledClassName() {
    return this.isDisabled() ? 'disabled' : '';
  },

  shouldNotRender() {
    const { router } = this.props;

    return !router.pathname.includes('supplies')
      || this.props.selectedStage == 'special_orders';
  },

  changeDirection() {
    this.props.dispatch(setOrderDirection(
      this.props.orderKind == 'reception' ? 'delivery' : 'reception'
    ))
  },

  buttonText() {
    let { orderKind, selectedStage } = this.props;

    switch (selectedStage) {
    case 'raw_material':
      return this.props.t(`common:order-delivery`);
    case 'finished_good':
      return this.props.t(`common:order-reception`);
    case 'work_in_progress':
      return this.props.t(`common:order-${orderKind}`);
    }
  },

  canShuffle() {
    return this.props.selectedStage == 'work_in_progress';
  },

  renderShuffle() {
    return (
      <div className="shuffle" onClick={this.changeDirection}>
        <i className="fa fa-random"></i>
      </div>
    )
  },

  render() {
    if (this.shouldNotRender()) { return null }
    let { orderKind } = this.props;

    return (
      <div className="supplies-submit-button">
        <Link
          to={`/orders/summary/${orderKind}`}
          className={`desc ${this.disabledClassName()}`}
        >
          {this.buttonText()}{' '}
          <i className="fa fa-check"></i>
        </Link>
        {this.canShuffle() && this.renderShuffle()}
      </div>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    supplies: state.supplies.supplies.map((supply) => {
    return supply.count;
    }).filter(number => {
      return number != 0;
    }),
    orderKind: state.supplies.orderKind,
    selectedStage: state.supplies.selectedStage
  }
}

export default connect(mapStateToProps)(translate(['header'])(SuppliesSubmitButton))

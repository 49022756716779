export default(
  state = {
    branch: {
      map_url: '',
      map_width: 0,
      map_height: 0,
      spots: []
    }
  },
action
) => {
  switch (action.type) {
    case 'FETCH_BRANCH_SUCCESS':
      return {
        ...state,
        branch: action.data
      }

    default:
      return state
  }
}

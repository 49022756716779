import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import moment from 'moment/moment.js'
import momentEN from 'moment/locale/en-gb'
import momentPl from 'moment/locale/pl'
import momentCS from 'moment/locale/cs'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import Order from './Order'
import OrdersMassActions from './OrdersMassActions'
import { carrierFetch as fetchOrders } from '../../common/orders/actions'

let OrdersList = React.createClass({

  componentDidMount() {
    if (this.props.orders.length === 0) {
      this.props.dispatch(fetchOrders());
    }
  },

  filteredOrders() {
    if (this.props.searchText == '') {
      return this.props.orders;
    }

    return this.props.orders.filter((order) => {
      return order.number.includes(this.props.searchText) ||
        order.code.includes(this.props.searchText) ||
        order.item.description.toLowerCase().includes(this.props.searchText.toLowerCase());
    });
  },

  render() {
    return (
      <div>
        <OrdersMassActions />
        <table className="operator-orders" id="orders-list">
          <ReactCSSTransitionGroup
            component="tbody"
            transitionName="fade"
            component="tbody"
            transitionAppear={true}
            transitionAppearTimeout={2000}
            transitionEnterTimeout={3000}
            transitionLeaveTimeout={2500}
          >
            {this.filteredOrders().map(function(order, index) {
              return <Order
                key={order.id}
                index={index}
                updated_at={order.updated_at}
                order={order}
              />;
            })}
          </ReactCSSTransitionGroup>
        </table>
      </div>
    )
  }

})

let mapStateToProps = state => {
  return {
    orders: state.orders.orders.filter((order) => {
      return order.status == 'accepted' || order.status == 'taken' ||
        (order.status == 'new' && !order.quality_check_required);
    })
    .sort((a,b) => {
      return moment.utc(a.accepted_at || a.created_at)
        .diff(
          moment.utc(b.accepted_at || b.created_at)
        );
    })
  }
}

export default connect(mapStateToProps)(OrdersList)

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import Barcode from 'react-barcode'
import ReactDOM from 'react-dom'
import {translate} from 'react-i18next'

import ZoomedMap from '../../common/lib/map/ZoomedMap'
import MapContainer from '../../common/lib/map/Container'
import { DEFAULT_BAR_FORMAT } from '../../common/variables'
import { showDetails } from '../../common/scannerOrders/actions'

let OrderDetails = React.createClass({

  componentDidUpdate() {
    let scrollableDiv = ReactDOM.findDOMNode(this).parentNode;
    scrollableDiv.scrollTop = 0;
  },

  handleHideDetails() {
    this.props.dispatch(showDetails(null));
  },

  render() {
    let { order, t } = this.props

    return (
      <div className="order-container order-more">
        <div className="order-more-desc">
          {order.item.description}
        </div>
        <div className="order-actions">
        </div>
        <div className="order-more-map">
          <MapContainer style={{ height: 300 }}>
            <ZoomedMap
              image={{
                url: order.location.map_url,
                width: order.location.map_width,
                height: order.location.map_height
              }}
              positions={[this.props.order.spot]}
              selectedPositionId={this.props.order.spot.id}
              zoomId={this.props.order.id}
            />
          </MapContainer>
        </div>
        <div className="order-more-details">
          <div className="order-more-location-barcode">
            <div className="location-barcode-title">
              {t('locationCode')}
            </div>
            <div className="location-barcode-code">
              <Barcode
                value={order.location.symbol}
                format={DEFAULT_BAR_FORMAT}
              />
            </div>
          </div>

          <div className="order-more-item-barcode">
            <div className="item-title-code">
              <div className="item-barcode-title">
                {t('productCode')}
              </div>
              <div className="item-barcode-code">
                <Barcode
                  value={order.item.code}
                  format={DEFAULT_BAR_FORMAT}
                  width={1}
                  fontSize={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    order: state.orders.orders.filter(function(elem) {
      return elem.id == state.orders.showDetailsOrderId;
    })[0]
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrderDetails))

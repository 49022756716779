import React from 'react'
import {connect} from 'react-redux'

class ThemeProvider extends React.Component {
  render() {
    return (
      <div className={`theme-provider ${this.props.theme == 'dark' ? 'dark-theme' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    theme: state.app.theme,
  }
}

export default connect(mapStateToProps)(ThemeProvider)


export let getFetchConfig = {
  method: 'GET',
  credentials: 'same-origin',
  cache: 'default',
  headers: {
    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
  }
}

export let postFetchConfig = {
  method: 'POST',
  credentials: 'same-origin',
  cache: 'default',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
  }
}

export let patchFetchConfig = {
  method: 'PATCH',
  credentials: 'same-origin',
  cache: 'default',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
  }
}

export let deleteFetchConfig = {
  method: 'DELETE',
  credentials: 'same-origin',
  cache: 'default',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
  }
}

export function parseJSON(response) {
  return response.json()
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {

    if (response.status == 422) {
      response.json().then(object => {
        new Flash(
         object.errors.join(', '),
         'alert'
        );
      })
    }

    var error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

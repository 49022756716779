import i18n from 'i18next'

var resBundle = require(
  "i18next-resource-store-loader!./assets/i18n/index.js"
)

export function initializeI18n(lang) {
  return i18n
    .init({
    lng: lang,
    fallbackLng: 'en',
    preload: ['pl', 'en'],

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    debug: true,

    interpolation: {
      escapeValue: false // not needed for react!!
    },
    resources: resBundle
  });
}

export default i18n;

import Cookies from 'js-cookie'

export default (
  state = {
    orders: [],
    showDetailsOrderId: false,
    message: null,
    status: null
  },
  action
) => {
  switch (action.type) {

    case 'FETCH_ORDERS_SUCCESS':
      return {
        ...state,
        orders: action.data
      }

    case 'PLACE_ORDERS_REQUEST':
      return {
        ...state,
        isSendingOrders: true
      }

    case 'PLACE_ORDERS_SUCCESS':
      return {
        ...state,
        isSendingOrders: false,
        ...action.data
      }

    case 'RESET_ORDERS_STATUS':
      return {
        ...state,
        message: null,
        status: null
      }

    case 'SHOW_ORDER_DETAILS':
      return {
        ...state,
        showDetailsOrderId: action.id
      }


    case 'UPDATE_ORDER':
      let orders = [];
      let operator_id = Cookies.get('guessed_operator_id');

      if (state.orders == []) {
        orders = [action.data];
      } else if (state.orders.map(order => order.id).includes(action.data.id)) {
        orders = state.orders.map((order) => {
          return order.id == action.data.id ? action.data : order
        });
      } else {
        orders = [action.data].concat(state.orders);
      }

      let filtered_orders = orders.filter(function(order) {
        return order.operator_id == operator_id;
      });

      return {
        ...state,
        orders: filtered_orders
      }


    default:
      return state
  }
}

import React from 'react'
import Cookies from 'js-cookie'
import { setTheme } from '../app/actions'
import {connect} from 'react-redux'

const cookiesPortableThemeKey = 'portable-theme'

class ThemeSwitch extends React.Component {

  changeTheme(newTheme) {
    Cookies.set(cookiesPortableThemeKey, newTheme)
    this.props.dispatch(setTheme(newTheme))
  }

  componentDidMount() {
    this.props.dispatch(setTheme(Cookies.get(cookiesPortableThemeKey) || 'dark'))
  }

  render() {
    return (
      <div>
        {
          this.props.theme == 'light'
            ? <i className="fa fa-sun-o" onClick={() => this.changeTheme('dark')}></i>
            : <i className="fa fa-moon-o" onClick={() => this.changeTheme('light')}></i>
        }
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    theme: state.app.theme,
  }
}

export default connect(mapStateToProps)(ThemeSwitch)


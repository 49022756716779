import { getFetchConfig, postFetchConfig, parseJSON, checkStatus } from '../../common/fetchHelper'

import Promise from 'bluebird'
if (!window.Promise) {
  window.Promise = Promise;
}

function fetchSuccess(orders) {
  return { type: 'FETCH_ORDERS_SUCCESS', data: orders }
}

function fetchFailure(errors) {
  return { type: 'FETCH_ORDERS_FAILURE', errors }
}

function placeOrdersRequest() {
  return { type: 'PLACE_ORDERS_REQUEST' }
}

function placeOrdersSuccess(body) {
  return { type: 'PLACE_ORDERS_SUCCESS', data: body }
}

function placeOrdersFailure(error) {
  return { type: 'PLACE_ORDERS_FAILURE', error: error }
}

export function resetOrdersStatus() {
  return { type: 'RESET_ORDERS_STATUS' }
}

export function update(order) {
  return { type: 'UPDATE_ORDER', data: order }
}

export function showDetails(id) {
  return { type: 'SHOW_ORDER_DETAILS', id: id }
}

export function fetchScannerOrders() {
  return (dispatch, getState) => {
    let url = '/api/v1/scanner/orders.json'

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
          dispatch(fetchSuccess(data));
          return data;
        })
      .catch((error) => {
        return;
      });
  }
}

export function placeOrder(code) {
  return (dispatch, getState) => {

    dispatch(placeOrdersRequest());
    const url = `/api/v1/scanner/cards/scan?number=${code}`

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        dispatch(placeOrdersSuccess(res));
        dispatch(fetchScannerOrders());
        return res;
      })
      .catch((error) => {
        dispatch(placeOrdersFailure(error));
        return;
      });
  }
}

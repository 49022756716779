import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import { translate } from 'react-i18next'

import Button from '../../common/lib/Button'
import { IncreaseCount, DecreaseCount } from '../../common/supplies/actions'

let Supply = React.createClass({

  getInitialState: function() {
    return {
      decreaseButton: false,
      increaseButton: true
    }
  },

  handleCountIncrease(e) {
    e.preventDefault();
    this.props.dispatch(IncreaseCount(this.props.supply));
    this.setState({decreaseButton: true});
  },

  handleCountDecrease(e) {
    e.preventDefault();
    this.props.dispatch(DecreaseCount(this.props.supply));
    this.setState({increaseButton: true});
  },

  isDecreaseButtonDisabled() {
    return (!(this.state.decreaseButton) || (this.props.supply.count == 0));
  },

  isIncreaseButtonDisabled() {
    const last_elem = this.props.supply.multiples[this.props.supply.multiples.length-1];
    return (!(this.state.increaseButton) || (this.props.supply.count == last_elem));
  },

  render() {
    let { supply, t } = this.props;

    return  (
      <div className="supply-location">
        <div className={"searchable-supply supply-container supply-" + (this.props.index % 2 == 0 ? 'even' : 'odd')}>
          <div className="supply-box">
            <div className="supply-meta">
              <div className="pr4">
                <span className="supply-code">{supply.item.code}, </span>
                <span className="supply-description">{supply.item ? supply.item.description : ''}</span>
              </div>
              {supply.item.image_thumb_url &&
                <div className="supply-thumb">
                  <img src={supply.item.image_thumb_url} className="supply-image" />
                </div>
              }
            </div>
            <div className="supply-right-side">
              <div className="supply-quantity">
                <span className="supply-quantity-description">{parseInt(supply.batch_quantity)} {supply.item.unit} </span>
                x
                <span className="supply-quantity-controls">
                  <Button
                    className={`qty qtyminus ${this.isDecreaseButtonDisabled() && 'disabled'}`}
                    disabled={this.isDecreaseButtonDisabled()}
                    onClick={(e) => this.handleCountDecrease(e)}
                  >
                    <i className="fa fa-minus"></i>
                  </Button>
                  <span className='count'>{supply.count < 100 ? supply.count : '∞'}</span>
                  <Button
                    className={`qty qtyplus ${this.isIncreaseButtonDisabled() && 'disabled'}`}
                    disabled={this.isIncreaseButtonDisabled()}
                    onClick={(e) => this.handleCountIncrease(e)}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </span>
              </div>
              {(supply.item.stock_quantity == -1) 
                ? ''
                : <div className="right" style={{ fontSize: '0.6em', marginTop: '5px' }}>{t('stock_quantity')}: {supply.item.stock_quantity}</div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

});

export default connect()(translate(['supplies'])(Supply))

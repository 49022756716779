import React, {PropTypes} from 'react'
import {connect} from 'react-redux'

import { showDetails, check } from '../../common/orders/actions'
import FromNow from '../../common/lib/FromNow'
import SetIntervalMixin from '../../common/lib/mixins/SetIntervalMixin'
import OrderMixin from '../../common/lib/mixins/OrderMixin'
import OrderStatusIcon from '../../common/lib/OrderStatusIcon'
import OrderLocation from '../../common/lib/OrderLocation'

let Order = React.createClass({
  mixins: [SetIntervalMixin, OrderMixin],

  render() {
    let { order } = this.props;

    return (
      <tr className={this.rowClassNames()} onClick={this.handleShowDetails}>
          <td className='status'></td>
          <td className={"order-meta " + this.highlighted()}>
            <div className='line'>
              <div className="formatted-date">
                <OrderStatusIcon order={order}/>
                <FromNow datetime={order.accepted_at || order.created_at} />
                {order.pretty_location}
              </div>
              <OrderLocation order={order} />
            </div>
            <div className='line ml6'>
              <div className="order-code">{order.code},</div>
              {this.quantity()}
            </div>
          </td>
          <td className="order-details">
          { this.checkBox()}
            {order.item.image_thumb_url &&
              <div className="order-thumb">
                <img src={order.item.image_thumb_url} className="order-image" />
              </div>
            }
            <div className="order-description">
              {order.item.description}
            </div>
          </td>
      </tr>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    showDetailsOrderId: state.orders.showDetailsOrderId,
    checkedOrders: state.orders.checkedOrders,
    checkedOrdersStatus: state.orders.checkedOrdersStatus
  }
}

export default connect(mapStateToProps)(Order)

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, connect } from 'react-redux'
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './i18n'

import AppReducer from './common/app/reducer'

import App from './admin/app/App'
import BranchesReducer from './common/branches/reducer'
import LocationsReducer from './common/locations/reducer'

import { setCSRFToken } from './common/app/actions'
import { adminFetch as fetchLocation } from './common/locations/actions'
import { fetchBranches } from './common/branches/actions'

export function render() {
  let reducers = combineReducers({
    app: AppReducer,
    locations: LocationsReducer,
    branches: BranchesReducer
  })

  let store = createStore(reducers, compose(
      applyMiddleware(thunk),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    ))

  let i18nObj = initializeI18n(document.getElementsByName('language')[0].dataset.lang);
  store.dispatch(setCSRFToken(document.getElementsByName('csrf-token')[0].content))
  if (document.getElementsByName('location_id').length != 0) {
    store.dispatch(fetchLocation(document.getElementsByName('location_id')[0].dataset.id));
  }
  if (document.getElementsByName('branch_id').length != 0) {
    store.dispatch(fetchBranches(document.getElementsByName('branch_id')[0].dataset.id));
  }

  const node = document.getElementById('admin-app')

  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={ i18nObj }>
          <App />
      </I18nextProvider>
    </Provider>,
    node
  )

  document.addEventListener(
    "turbolinks:before-visit",
    () => {
      ReactDOM.unmountComponentAtNode(node)
    },
    { once: true }
  )
}


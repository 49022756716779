import React from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import Modal from '../../common/lib/Modal'

let OrderStatusIcon = React.createClass({

  handleClick(e) {
    e.stopPropagation();

    if (!!this.props.order.carrier_info) {
      this.refs.carrierInfoModal.showModal()
    }
    if (this.props.order.status == 'cancelled') {
      this.refs.cancelInfoModal.showModal()
    } 
  },

  renderCarrierInfoModal() {
    return <Modal refName='carrierInfoModal' ref='carrierInfoModal'>
      <p className="pl10 pr10">
        {this.props.t(`carrierInfo.${this.props.order.carrier_info}`)}
      </p>
    </Modal>;
  },

  renderCancelInfoModal() {
    return <Modal refName='cancelInfoModal' ref='cancelInfoModal'>
      <p>
        {!!this.props.order.cancel_reason &&
          this.props.t(`cancelReasons.${this.props.order.cancel_reason}`)}
      </p>
    </Modal>;
  },

  render() {
    let { order } = this.props;

    return (
      <span onClick={(e) => this.handleClick(e)}>
        {!!order.carrier_info && <i className="fa fa-warning fa-fw fa-blink"></i> }
        {order.status == 'cancelled' && <i className="fa fa-times-circle fa-fw"></i> }
        {!!!order.carrier_info && order.status != 'cancelled'
          && <i className="fa fa-clock-o fa-fw"></i> }

        {!!order.carrier_info && this.renderCarrierInfoModal()}
        {order.status == 'cancelled' && this.renderCancelInfoModal()}
      </span>
    );
  }

});

export default translate(['orders'])(OrderStatusIcon)

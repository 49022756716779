import React from 'react';

export default (props) => {
  let {
    color = 'default',
    size = 'default',
    block = false,
    active = false,
    disabled = false,
    children,
    className,
    ...other
  } = props;

  let classNames = ["btn"];
  if (props.className)
    classNames = [props.className];
  classNames.push(`btn-${color}`);
  if (size != 'default')
    classNames.push(`btn-${size}`);
  if (block)
    classNames.push(`btn-block`);
  if (active)
    classNames.push(`btn-active`);

  return (
    <button
      className={classNames.join(" ")}
      disabled={disabled}
      {...other}
    >
      { children }
    </button>
  )
};

const AUTO_ZOOM_PADDING = 80

/**
 * Zooms in to show all positions, leaves padding in px.
 * Arguments: Map props
 * Returns: object with zoom and pan
 */
export default function zoomToPositions(
  { positions, containerWidth, containerHeight },
  padding = AUTO_ZOOM_PADDING
) {
  let zoom = 1
  let area = getPositionsArea(positions)
  let availableArea = getAvailableArea(containerWidth, containerHeight, padding)

  if (!areaFitsInto(area, availableArea)) {
    zoom = Math.min(availableArea.width / area.width, availableArea.height / area.height)
  }

  return {
    zoom: zoom,
    pan: {
      x: area.mid.x - containerWidth / zoom / 2,
      y: area.mid.y - containerHeight / zoom / 2
    }
  }
}

/**
 * Returns an object with min, mid, max coordinates of given positions and
 * width and height of the area defined by these coordinates
 */
function getPositionsArea(positions) {
  let edges = getPositionsEdgeCoordinates(positions)
  let { min, max } = edges

  return {
    ...edges,
    mid: {
      x: (min.x + max.x) / 2,
      y: (min.y + max.y) / 2,
    },
    width: max.x - min.x,
    height: max.y - min.y
  }
}

function getPositionsEdgeCoordinates(positions) {
  let { xs, ys } = groupPositionsCoordinates(positions)

  return {
    min: {
      x: Math.min(...xs),
      y: Math.min(...ys)
    },
    max: {
      x: Math.max(...xs),
      y: Math.max(...ys)
    },
  }
}

function groupPositionsCoordinates(positions) {
  return {
    xs: positions.map(pos => pos.position_x),
    ys: positions.map(pos => pos.position_y)
  }
}

function getAvailableArea(containerWidth, containerHeight, padding) {
  return {
    width: containerWidth - padding * 2,
    height: containerHeight - padding * 2
  }
}

function areaFitsInto(a, b) {
  return a.width <= b.width && a.height <= b.height
}

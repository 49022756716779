export default (
  state = {
    orders: []
  },
  action
) => {
  switch (action.type) {

    case 'FETCH_ORDERS_SUCCESS':
      return {
        ...state,
        orders: action.data
      }

    case 'UPDATE_ORDER':
      let orders = [];
      if (state.orders == []) {
        orders = [action.data];
      } else if (state.orders.map(order => order.id).includes(action.data.id)) {
        orders = state.orders.map((order) => {
          return order.id == action.data.id ? action.data : order
        });
      } else {
        orders = [action.data].concat(state.orders);
      }

      const allowedStatuses = ['accepted', 'taken', 'new'];
      let filteredOrders = orders.filter((order) => {
        return allowedStatuses.includes(order.status);
      });

      return {
        ...state,
        orders: filteredOrders
      }

    default:
      return state
  }
}

import {getFetchConfig, postFetchConfig, parseJSON, checkStatus} from '../../common/fetchHelper'

import Promise from 'bluebird'
if (!window.Promise) {
  window.Promise = Promise;
}

function fetchSuccess(orders) {
  return { type: 'FETCH_ORDERS_SUCCESS', data: orders }
}

function fetchFailure(errors) {
  return { type: 'FETCH_ORDERS_FAILURE', errors }
}

function placeOrdersRequest() {
  return { type: 'PLACE_ORDERS_REQUEST' }
}

function placeOrdersSuccess() {
  return { type: 'PLACE_ORDERS_SUCCESS' }
}

function placeOrdersFailure(error) {
  return { type: 'PLACE_ORDERS_FAILURE', error: error }
}

export function update(order) {
  return { type: 'UPDATE_ORDER', data: order }
}

export function changeRemarks(remarks) {
  return { type: 'CHANGE_REMARKS', remarks: remarks }
}

export function filteredUpdate(order) {
  return (dispatch, getState) => {
    const location_ids = getState().app.locationIds;

    if (location_ids == []) {
      dispatch(update(order));
    } else if (location_ids.includes(order.location_id)) {
      dispatch(update(order));
    }
  }
}

export function showDetails(id) {
  return { type: 'SHOW_ORDER_DETAILS', id: id }
}

export function check(id) {
  return { type: 'CHECK_ORDER', id: id }
}

export function setCancelReasons(hash) {
  return { type: 'SET_ORDER_CANCEL_REASONS', data: hash }
}

export function setInfos(hash) {
  return { type: 'SET_ORDER_INFOS', data: hash }
}

export function carrierTakeOrder(id) {
  return (dispatch, getState) => {
    let url = '/api/v1/carrier/orders/pick_up.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({ids: [id]})

    return fetch(url, parameters)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return;
      });
  }
}

export function carrierDeliverOrder(id) {
  return (dispatch, getState) => {
    let url = '/api/v1/carrier/orders/deliver.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({ids: [id]})

    return fetch(url, parameters)
      .then((res) => {
        dispatch(showDetails(null));
        return res;
      })
      .catch((error) => {
        return;
      });
  }
}

export function carrierCancelOrder(id, reason = null) {
  return (dispatch, getState) => {
    let url = '/api/v1/carrier/orders/cancel.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({
      ids: [id],
      reason: reason
    })

    return fetch(url, parameters)
      .then((res) => {
        dispatch(showDetails(null));
        return res;
      })
      .catch((error) => {
        return;
      });
  }
}

export function operatorCancelOrder(id, reason = null) {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/orders/' + id + '/cancel.json';

    let parameters = postFetchConfig;
    parameters['body'] = JSON.stringify({
      reason: reason
    });

    return fetch(url, parameters)
      .then((res) => {
        dispatch(showDetails(null));
        dispatch(operatorFetch());
        return res;
      })
      .catch((error) => {
        dispatch(fetchFailure(error.res.body.errors));
        return error;
      })
  }
}

export function operatorReturnOrder(id, quantity) {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/orders/' + id + '/return_quantity.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({
      quantity: quantity
    })

    return fetch(url, parameters)
      .then((res) => {
        res.json().then((order) => dispatch(update(order)));
      })
      .catch((error) => {
        return;
      });
  }
}

export function operatorConfirmDelivery(id) {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/orders/' + id + '/confirm_delivery.json';
    
    return fetch(url, postFetchConfig)
      .then((res) => {
        res.json().then((order) => dispatch(update(order)));
        return;
      })
      .catch((error) => {
        return;
      });
  }
}

export function carrierInformOrder(id, info = null) {
  return (dispatch, getState) => {
    let url = '/api/v1/carrier/orders/inform.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({
      ids: [id],
      info: info
    })

    return fetch(url, parameters)
      .then((res) => {
        dispatch(showDetails(null));
        return;
      })
      .catch((error) => {
        return;
      });
  }
}

export function operatorFetch(search = '') {
  return (dispatch, getState) => {
    let url = '/api/v1/operator/orders.json'

    if (search != '') {
      url = url + '?search=' + encodeURIComponent(search);
    }

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        dispatch(fetchSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(fetchFailure(error))
        return;
      });
  }
}

export function carrierFetch(search = '') {
  return (dispatch, getState) => {
    let url = '/api/v1/carrier/orders.json'

    if (search != '') {
      url = url + '?search=' + encodeURIComponent(search);
    }

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        dispatch(fetchSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
        throw error;
      })
  }
}

export function qualityFetch(search = '') {
  return (dispatch, getState) => {
    let url = '/api/v1/quality/orders.json'

    if (search != '') {
      url = url + '?search=' + encodeURIComponent(search);
    }

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        dispatch(fetchSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
        throw error;
      })
  }
}

export function operatorReorder(id) {
  return (dispatch, getState) => {

    let url = '/api/v1/operator/orders/' + id + '/reorder.json';
    let CSRFToken = getState().app.CSRFToken;

    return fetch(url, postFetchConfig)
      .then((data) => {
        dispatch(showDetails(null));
        return data;
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
        return;
      });
  }
}

export function operatorPlaceOrder(supplies, spot_id, direction = 'reception', remarks = '') {
  return (dispatch, getState) => {
    let location_id = getState().locations.selectedId;
    let plant_id = getState().app.plant_id;
    let CSRFToken = getState().app.CSRFToken;

    dispatch(placeOrdersRequest());

    Promise.map(supplies, (supply) => {
      let supply_id = supply.id;
      let quantity = supply.count;
      return operatorPushOrder(location_id, direction, spot_id, supply_id, plant_id, quantity, CSRFToken, remarks);
    }).then(() => {
      dispatch(placeOrdersSuccess());
    }).catch((error) => {
      dispatch(placeOrdersFailure(error));
    })
  }
}

function operatorPushOrder(location_id, direction, spot_id, supply_id, plant_id, quantity, CSRFToken, remarks) {
  const url = '/api/v1/operator/orders.json'

  let parameters = postFetchConfig
  parameters['body'] = JSON.stringify({
    'order': {
      location_id: location_id,
      spot_id: spot_id,
      supply_id: supply_id,
      plant_id: plant_id,
      quantity: quantity,
      direction: direction,
      remarks: remarks
    }
  })

  return fetch(url, parameters)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return;
    });
}
export function operatorPushSpecialOrder(special_order_id, location_id, spot_id) {
  return (dispatch, getState) => {
    const url = '/api/v1/operator/orders/place_special.json'
    const CSRFToken = getState().app.CSRFToken;

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({
      'special_order': {
        location_id: location_id,
        spot_id: spot_id,
        special_order_id: special_order_id
      }
    })

    return fetch(url, parameters)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return;
      });
  }
}

export function qualityAcceptOrder(id) {
  return (dispatch, getState) => {
    let url = '/api/v1/quality/orders/accept.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({ids: [id]})

    return fetch(url, parameters)
      .then((res) => {
        dispatch(showDetails(null));
        return;
      })
      .catch((error) => {
        return;
      });
  }
}

export function qualityRejectOrder(id) {
  return (dispatch, getState) => {
    let url = '/api/v1/quality/orders/reject.json';

    let parameters = postFetchConfig
    parameters['body'] = JSON.stringify({ids: [id]})

    return fetch(url, parameters)
      .then((res) => {
        dispatch(showDetails(null));
        return;
      })
      .catch((error) => {
        return;
      });
  }
}

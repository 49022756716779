import React, { PropTypes } from 'react'
import Dimensions from 'react-dimensions'

import Map from './Map'
import { zoomToPositions, zoomToFit } from './calculations'

/**
 * Starts zoomed as close as possible while showing all positions.
 * Resets zoom on window resize.
 */
const ZoomedMap = React.createClass({
  propTypes: Map.propTypes,

  getInitalState() {
    return {
      zoom: 1,
      pan: {
        x: 0,
        y: 0
      }
    }
  },

  componentWillReceiveProps(nextProps) {
    if ( this.props.positions != nextProps.positions){
      this.zoomToFit()

      setTimeout(this.setState(zoomToPositions(nextProps)), 200)
    }
  },

  componentWillMount() {
    this.zoomToFit()
  },

  componentDidMount() {
    setTimeout(this.zoomToPositions, 200)
  },

  zoomToFit() {
    this.setState(zoomToFit(this.props))
  },

  zoomToPositions() {
    this.setState(zoomToPositions(this.props))
  },

  render () {
    let { zoom, pan } = this.state

    return (
      <Map {...this.props} zoom={zoom} pan={pan} animate={true} />
    )
  }
})

export default Dimensions()(ZoomedMap)

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'

import Supply from './Supply'
import SpecialOrder from './SpecialOrder'
import SuppliesQuantityModal from './SuppliesQuantityModal'
import LocationSelect from '../app/LocationSelect'
import Search from '../../common/lib//Search'

import { fetchCategories, fetchSupplies } from '../../common/supplies/actions'
import { fetchLocations } from '../../common/locations/actions'

let SuppliesList = React.createClass({

  getInitialState: function() {
    return { searchText: '' }
  },

  componentWillMount() {
    this.props.dispatch(fetchCategories());
    this.props.dispatch(fetchSupplies(this.props.locationId));
    if (this.props.locations.length === 0) {
      this.props.dispatch(fetchLocations());
    }
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.locationId != nextProps.locationId) {
      this.props.dispatch(fetchSupplies(nextProps.locationId));
    }
  },

  filteredSupplies() {
    if (this.state.searchText == '') {
      return this.props.supplies;
    } else {
      return this.props.supplies.filter((supply) => {
        return (supply.item.description.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
          supply.item.code.includes(this.state.searchText));
      })
    }
  },

  selectedLocation() {
    return this.props.locations.filter((location) => {
      return location.id == this.props.locationId;
    })[0];
  },

  filteredSpecialOrders() {
    if ( this.selectedLocation() != undefined ) {
      return this.selectedLocation().special_orders
    } else {
      return []
    }
  },

  handleSearch(text) {
    this.setState({searchText: text});
  },

  renderSuppliesList() {
    return this.filteredSupplies().map((supply, index) => {
      return <Supply
        key={supply.id}
        index={index}
        supply={supply}
      />;
    })
  },

  renderSpecialOrdersList() {
    return this.filteredSpecialOrders().map((specialOrder, index) => {
      return <SpecialOrder
        key={specialOrder.id}
        index={index}
        specialOrder={specialOrder}
        currentLocationId={this.props.locationId}
        router={this.props.locations}
      />;
    })
  },

  render() {
    return (
      <div>
        <div className="content-wrapper">
          <div className="page-header">
            <LocationSelect />
            <Search onSearch={this.handleSearch} />
          </div>
          <div id="supplies-list" style={{ overflow: 'hidden' }}>
            <form id="order-form" action="/operator/orders/finalize" acceptCharset="UTF-8" method="post">
              {this.props.selectedStage == 'special_orders' && this.renderSpecialOrdersList()}
              {this.props.selectedStage != 'special_orders' && this.renderSuppliesList()}
            </form>
          </div>
        </div>
        <SuppliesQuantityModal />
      </div>
    )
  }

})

let mapStateToProps = state => {
  return {
    locationId: state.locations.selectedId,
    locations: state.locations.locations,
    selectedStage: state.supplies.selectedStage,
    supplies: state.supplies.supplies.filter((supply) => {
      const validStage = supply.item.stage == state.supplies.selectedStage;
      const validCategory = !state.supplies.selectedCategoryId || supply.item.category_ids.includes(state.supplies.selectedCategoryId);

      return validStage && validCategory;
    })
  }
}

export default connect(mapStateToProps)(SuppliesList)

import React, { PropTypes } from 'react'
import {translate} from 'react-i18next'

import Button from './Button'

const ConfirmButton = React.createClass({
  getInitialState() {
    return {
      isActive: false
    }
  },

  activate() {
    this.setState({
      isActive: true
    })
  },

  cancel() {
    this.setState({
      isActive: false
    })
  },

  confirm(e) {
    this.props.onClick(e)
    this.cancel()
  },

  render () {
    let { t, i18nLoadedAt, children, onClick, className, buttonWidth, ...otherProps } = this.props
    buttonWidth = buttonWidth ? buttonWidth : 158;
    className = className ? className : 'btn btn-group';

    if (this.state.isActive) {
      let style = { display: 'inline-block', width: '50%', margin: '0', padding: '4.2px 0px' };

      return (
        <div className={className} style={{whiteSpace: 'nowrap', width: `${buttonWidth}px`, padding: 0}}>
          <Button
            style={style}
            color="danger"
            onClick={this.cancel}
          >
            {t('no')}
          </Button>
          <Button
            style={style}
            color="success"
            onClick={this.confirm}
          >
            {t('yes')}
          </Button>
        </div>
      )
    } else {
      return (
        <Button
          {...otherProps}
          style={{whiteSpace: 'nowrap', width: `${buttonWidth}px`}}
          onClick={this.activate}
          className={className}
          color='blue'
        >
          {children}
        </Button>
    )
  }
  }
})

export default translate(['common'])(ConfirmButton)

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'

import { showDetails, check } from '../../common/orders/actions'
import FromNow from '../../common/lib/FromNow'
import SetIntervalMixin from '../../common/lib/mixins/SetIntervalMixin'
import OrderMixin from '../../common/lib/mixins/OrderMixin'
import OrderStatusIcon from '../../common/lib/OrderStatusIcon'
import OrderLocation from '../../common/lib/OrderLocation'

let Order = React.createClass({
  mixins: [SetIntervalMixin, OrderMixin],

  render() {
    let { order } = this.props;

    return (
      <tr className={this.rowClassNames() + ' non-clickable'}>
          <td className='status'></td>
          <td className="order-meta">
            <div className="formatted-date">
              <OrderStatusIcon order={order}/>
              <FromNow datetime={order.accepted_at || order.created_at} />
              {order.pretty_location}
            </div>
            <OrderLocation order={order} />
          </td>
          <td className="order-meta">
            <div className="order-code">{order.code},</div>
            {this.quantity()}
          </td>
          <td className="order-details">
            <div className="order-description">
              {order.item.description}
            </div>
          </td>
      </tr>
    )
  }
});

export default Order

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import ActionCable from 'actioncable';
import bowser from 'bowser';

import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './i18n'

import AppReducer from './common/app/reducer'
import OrdersReducer from './common/orders/reducer'
import SuppliesReducer from './common/supplies/reducer'
import LocationsReducer from './common/locations/reducer'

import Header from './quality/app/Header';
import App from './quality/app/App';

import { setCSRFToken, setCurrentPlant, setLanguage, setUserName } from './common/app/actions'
import { update as updateOrder} from './common/orders/actions'

require('./assets/stylesheets/application.sass');

let reducers = combineReducers({
  orders: OrdersReducer,
  supplies: SuppliesReducer,
  locations: LocationsReducer,
  app: AppReducer
});

let store = createStore(reducers, compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  ));

let consumer = ActionCable.createConsumer();
let connection = consumer.subscriptions.create('PlantOrdersChannel', {
  connected() {
    this.perform('follow', {
      plant_id: document.getElementsByName('current_plant_id')[0].dataset.id
    });
  },
  disconnected() {},
  received(order) {
    store.dispatch(updateOrder(order['order']));
  }
})

let i18nObj = initializeI18n(document.getElementsByName('language')[0].dataset.lang);
store.dispatch(setCSRFToken(document.getElementsByName('csrf-token')[0].content));
store.dispatch(setCurrentPlant(document.getElementsByName('current_plant_id')[0].dataset.id));
store.dispatch(setLanguage(document.getElementsByName('language')[0].dataset.lang));
store.dispatch(setUserName(document.getElementsByName('user_name')[0].dataset.name));

if (!bowser.msie) {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={ i18nObj }>
        <App />
      </I18nextProvider>
    </Provider>,
    document.getElementById('quality-app')
  )
} else {
  let el = document.getElementsByClassName('unsupported_browser')[0];
  el.setAttribute("style", "display:block;");
}

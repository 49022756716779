import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import OrderDetails from './OrderDetails'
import OrdersList from './OrdersList'
import Search from '../../common/lib/Search'
import { showDetails, carrierFetch as fetchOrders } from '../../common/orders/actions'

let OrdersWrapper = React.createClass({

  getInitialState: function() {
    return { searchText: '' }
  },

  shouldShowDetails() {
    return !!this.props.showDetailsOrderId && !!this.props.detailedOrder;
  },

  handleSearch(text) {
    this.setState({searchText: text});
  },

  refreshClick(e) {
    e.preventDefault();
    this.props.dispatch(fetchOrders());
    this.refs.searchBox.clear();
    this.props.dispatch(showDetails(null));
  },

  scrollListUp() {
    let scrollableDiv = this.refs.orderList;
    scrollableDiv.scrollTop = 0;
  },

  render() {
    const { t } = this.props;

    return (
      <div className="content-wrapper">
        <div className="page-header">
          <a className="refresh-button" href="#" onClick={this.refreshClick}>
            <i className="fa fa-refresh"></i>&nbsp;{t('common:refresh')}
          </a>
          <Search onSearch={this.handleSearch} ref='searchBox'/>
        </div>
        <div ref='orderList' className={`scroll-wrapper ${this.shouldShowDetails() && 'half'}`}>
          <OrdersList searchText={this.state.searchText}/>
        </div>
        {
          this.shouldShowDetails() &&
            <div className='scroll-wrapper half'>
              <OrderDetails scrollListUp={this.scrollListUp}/>
            </div>
        }
      </div>
    )
  }

})

let mapStateToProps = state => {
  return {
    showDetailsOrderId: state.orders.showDetailsOrderId,
    detailedOrder: state.orders.orders.filter((order) => {
      return order.status == 'accepted' || order.status == 'taken' ||
        (order.status == 'new' && !order.quality_check_required);
    }).filter(function(elem) {
      return elem.id == state.orders.showDetailsOrderId;
    })[0]
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrdersWrapper))

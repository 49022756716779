import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, connect } from 'react-redux'
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import ActionCable from 'actioncable';
import bowser from 'bowser';

import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './i18n'

import AppReducer from './common/app/reducer'
import scannerOrdersReducer from './common/scannerOrders/reducer'

import App from './scanner/app/App'

import { setCSRFToken, setLanguage, setAvailableLocales } from './common/app/actions'
import { update as updateOrder, fetchScannerOrders as fetchOrders } from './common/scannerOrders/actions'

require('./assets/stylesheets/application.sass');

let reducers = combineReducers({
  app: AppReducer,
  orders: scannerOrdersReducer
})

let store = createStore(reducers, compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  ))

let consumer = ActionCable.createConsumer();
let connection = consumer.subscriptions.create('PlantOrdersChannel', {
  connected() {
    this.perform('follow_all');
  },
  disconnected() {},
  received(order) {
    store.dispatch(updateOrder(order['order']));
  }
})

store.dispatch(setCSRFToken(document.getElementsByName('csrf-token')[0].content))
store.dispatch(setLanguage(document.getElementsByName('language')[0].dataset.lang));
store.dispatch(setAvailableLocales(document.getElementsByName('available_locales')[0].dataset.lang))
store.dispatch(fetchOrders());

let i18nObj = initializeI18n(document.getElementsByName('language')[0].dataset.lang);

if (!bowser.msie) {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={ i18nObj }>
        <App />
      </I18nextProvider>
    </Provider>,
    document.getElementById('scanner-app')
  )
} else {
  let el = document.getElementsByClassName('unsupported_browser')[0];
  el.setAttribute("style", "display:block;");
}

import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import { placeOrder, resetOrdersStatus, showDetails } from '../../common/scannerOrders/actions'

const Scanner = React.createClass({

  componentDidMount() {
     setInterval(() => {
       this.refs.scannerInput.focus();
     }, 1000);
   },


  handleKeyPress: function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.dispatch(placeOrder(e.target.value));
    }
  },

  componentWillReceiveProps(nextProps) {
    this.showStatusMessage(nextProps.status, nextProps.message);
  },

  clearInput () {
    this.refs.scannerInput.value = '';
    this.props.dispatch(showDetails(null));
  },

  showStatusMessage(status, message) {
    if (status != null) {
      new Flash(
       message,
        status == '1' ? 'notice' : 'alert'
      );

     this.clearInput();
     this.props.dispatch(resetOrdersStatus());
    }
  },

  render() {
    return (
      <div id="scanner">
        <form action="/scanner/cards/scan" method="post">
          <input
            onKeyPress={this.handleKeyPress}
            ref='scannerInput'
            type="text"
            id="barcode-field"
            autoFocus="autofocus"
            placeholder={this.props.t('placeholder')}
          />
        </form>
      </div>
    )
  }
})

let mapStateToProps = state => {
  return {
    app: state.app,
    message: state.orders.message,
    status: state.orders.status
  }
}

export default connect(mapStateToProps)(translate(['scanner'])(Scanner))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'

let Search = React.createClass({
  clear () {
    this.refs.searchInput.value = '';
  },

  render () {
    return (
      <div className="search inline-block">
        <form id="search-form" acceptCharset="UTF-8" method="get">
          <input
            type="text"
            name="search"
            id="search-input"
            className="search-input search-input-normal"
            ref="searchInput"
            onChange={(e) => this.props.onSearch(e.target.value)}
            autoComplete="off"
          />
          <span className="search-icon fa fa-search"></span>
        </form>
      </div>
    )
  }
})

export default Search

import React from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import Button from '../../common/lib/Button'
import { massInformOrder } from '../../common/orders/massActions'

let InformOrder = React.createClass({

  informOrderClick(info) {
    this.props.dispatch(massInformOrder(this.props.orderIds, info));
  },

  infoLinks() {
    const infos = this.props.infos;
    return infos.map((info) => {
      return <Button
        key={info}
        color='info'
        onClick={() => this.informOrderClick(info)}>
        {this.props.t(`carrierInfo.${info}`)}
      </Button>;
    });
  },

  render: function() {
    return (
      <div>
        <p className='center carrier-info-modal-content'>{this.props.t('infoReason')}</p>
        {this.infoLinks()}
      </div>
    );
  }

});

function mapStateToProps(state, ownProps) {
  return {
    infos: state.orders.infos
  }
}

export default connect(mapStateToProps)(translate(['orders'])(InformOrder))

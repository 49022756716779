import React, { PropTypes } from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import OrderDirectionIcon from './OrderDirectionIcon'

let OrderLocation = React.createClass({
  propTypes: {
    order: PropTypes.object.isRequired
  },

  renderSpotLocation() {
    let { order } = this.props;

    return (
      <span className="order-meta-spot">
        &nbsp;{order.location.symbol}:
        &nbsp;{order.spot_symbol}
      </span>
    )
  },

  renderStockLocation() {
    let { order } = this.props;

    return (
      <span className="order-meta-spot">
        &nbsp;{order.location.symbol}:
        &nbsp;{order.to_stock}
      </span>
    )
  },

  renderLocation() {
    let { order } = this.props;

    if (order.status == 'taken') {
      return this.renderStockLocation();
    } else {
      return this.renderSpotLocation();
    }
  },

  render() {
    let { order } = this.props;

    return (
      <div className=''>
        <OrderDirectionIcon order={order} />
        {this.renderLocation()}
      </div>
    );
  }

});

export default translate(['orders'])(OrderLocation)

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, connect } from 'react-redux'
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import ActionCable from 'actioncable';

import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './i18n'

import AppReducer from './common/app/reducer'
import DisplayOrdersReducer from './common/displayOrders/reducer'
import LocationsReducer from './common/locations/reducer'

import App from './display/app/App'

import { setCSRFToken, setUserLocations, setCurrentPlant,
         setLanguage, setDisplayName } from './common/app/actions'
import { update as updateOrder } from './common/displayOrders/actions'

require('./assets/stylesheets/application.sass');

let reducers = combineReducers({
  app: AppReducer,
  orders: DisplayOrdersReducer,
  locations: LocationsReducer
})

let store = createStore(reducers, compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  ))

let consumer = ActionCable.createConsumer();
let connection = consumer.subscriptions.create('PlantOrdersChannel', {
  connected() {
    this.perform('follow', {
      plant_id: document.getElementsByName('current_plant_id')[0].dataset.id
    });
  },
  disconnected() {},
  received(order) {
    store.dispatch(updateOrder(order['order']));
  }
})

store.dispatch(setLanguage(document.getElementsByName('language')[0].dataset.lang))
store.dispatch(setCurrentPlant(document.getElementsByName('current_plant_id')[0].dataset.id))
store.dispatch(setUserLocations(document.getElementsByName('user_location_ids')[0].dataset.locations))
store.dispatch(setDisplayName(document.getElementsByName('display_name')[0].dataset.name))

let i18nObj = initializeI18n(document.getElementsByName('language')[0].dataset.lang);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={ i18nObj }>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('display-app')
)

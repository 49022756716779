import {DropModal as Modal} from 'boron'
import React from 'react'
import Button from './../../common/lib/Button'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

let modalStyle = {
  background: '#4F6F8F',
  color: '#FFF',
  width: '800px',
  textAlign: 'center'
};

let contentStyle = {
    height: '100%',
    maxWidth: '100%',
    wordWrap: 'break-word'
};


const SuppliesQuantityModal = React.createClass({
  getInitialState() {
    return {
      inputValue: 10
    }
  },

  showModal: function() {
    this.refs['suppliesQuantityModal'].show();
  },

  hideModal: function() {
    this.refs['suppliesQuantityModal'].hide();
  },

  componentDidMount() {
    this.props.dispatch({ type: 'SET_SUPPLY_MODAL', modal: this.refs['suppliesQuantityModal'] });
  },

  handleSaveClick() {
    this.props.dispatch({
      type: 'INCREASE_SUPPLY_COUNT',
      fixedCount: this.state.inputValue,
      supply: this.props.supply
    });
    this.hideModal();
  },

  handleInputChange(e) {
    const newValue = e.target.value

    this.setState((s) => ({ inputValue: Number(newValue) ? newValue : s.inputValue }))
  },

  render: function() {
    return (
      <Modal ref={'suppliesQuantityModal'} className='modal' modalStyle={modalStyle} contentStyle={contentStyle} keyboard={true}>
        <div style={{ padding: '10px' }}>
          {this.props.supply && this.props.supply.item.description}
          <br />
          <span style={{ fontSize: '0.7em', marginTop: '20px' }}>
            {this.props.t(`common:quantity`)}: 
            <input
              type="number"
              value={this.state.inputValue}
              onChange={this.handleInputChange}
            />
          </span>

          <Button color='emerald' onClick={this.handleSaveClick} style={{ width: 'auto', margin: '40px auto 0' }}>Zapisz</Button>
        </div>

        <Button color='blue' onClick={this.hideModal}>{this.props.t(`common:cancel`)}</Button>
      </Modal>
    );
  }

});

function mapStateToProps(state) {
  return {
    supply: state.supplies.qunatityModalSupply
  }
}

export default connect(mapStateToProps)(translate(['common'])(SuppliesQuantityModal))
import { findDOMNode } from 'react-dom'

/**
 * Helper methods for calculating component and event positions
 */
export default {
  /**
   * Maps React TouchList with touch positions relative to the component
   */
  getTouchListPositions(touchList) {
    let touches = Array.prototype.slice.apply(touchList)
    return touches.map(t => this.getEventPosition(t))
  },

  /**
   * Calculates event position relative to the component
   */
  getEventPosition(e) {
    let { left, top } = this.getPagePosition()
    return {
      x: e.pageX - left,
      y: e.pageY - top
    }
  },

  /**
   * Returns component position relative to the page
   */
  getPagePosition() {
    return findDOMNode(this).getClientRects()[0]
  }
}

import React, { PropTypes } from 'react'
import { findDOMNode } from 'react-dom'
import Dimensions from 'react-dimensions'
import { connect } from 'react-redux'

import MapContainer from '../../common/lib/map/Container'
import Map from '../../common/lib/map/Map'
import { Mouse, Touch } from '../../common/lib/map/zoom'
import { pan as panCalculation, zoomToFit, zoomToPoint } from '../../common/lib/map/calculations'
import PositionMixin from '../../common/lib/mixins/PositionMixin'
import { adminUpdatePostSpot, createPostSpot } from '../../common/locations/actions'

const AdminMapEditSpots = React.createClass({
  mixins: [PositionMixin],
  propTypes: {
    ...Map.propTypes,
    zoomId: PropTypes.any,
    onClick: PropTypes.func
  },

  getInitialState() {
    return {
      zoom: 1,
      pan: {
        x: 0,
        y: 0
      },
      grabbedPositionId: null,
      positions: []
    }
  },

  componentDidMount() {
    this.zoomToFit()
    this.setState({
      positions: this.props.positions
    })
    this.setState({
      positions: [
        ...this.props.positions
      ]
    })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.zoomId != this.props.zoomId) {
      this.zoomToFit()
    }
    this.setState({
      positions: [
        ...nextProps.positions
      ]
    })
  },

  zoomToFit() {
    this.setState(zoomToFit(this.props))
  },

  zoom(focal, factor) {
    let newZoom = this.state.zoom * (1 - factor)
    this.setState(zoomToPoint({ ...this.props, ...this.state }, focal, newZoom))
  },

  pan(displacement) {
    if (this.state.grabbedPositionId !== null) {
      this.movePosition(this.state.grabbedPositionId, displacement)
    } else {
      this.setState(panCalculation({ ...this.props, ...this.state }, displacement))
    }
  },

  click(e) {
    let { pan, zoom } = this.state

    if (this.state.grabbedPositionId === null) {
      let eventPosition = this.getEventPosition(e)
      let mapPosition = {
        x: (eventPosition.x / zoom + pan.x),
        y: (eventPosition.y / zoom + pan.y)
      }
      this.addNewPosition(mapPosition)
    }
  },

  tap(e, eventPosition) {
    let { pan, zoom } = this.state

    if (this.state.grabbedPositionId === null) {
      let mapPosition = {
        x: (eventPosition.x / zoom + pan.x),
        y: (eventPosition.y / zoom + pan.y)
      }
      this.addNewPosition(mapPosition)
    }
  },

  release(e) {
    this.setState({ grabbedPositionId: null })
  },

  addNewPosition({ x, y }) {
    let newPosition = {
      position_x: x,
      position_y: y,
      id: this.state.positions.length + 1,
      symbol: (this.state.positions.length + 1).toString(),
      description: '',
      character: 0
    };

    this.props.dispatch(createPostSpot(newPosition));
    this.setState({
      positions: [
        ...this.state.positions,
        newPosition
      ]
    })
  },

  movePosition(id, displacement) {
    this.setState({
      positions: this.state.positions.map(position => {
        if (position.id === this.state.grabbedPositionId) {
          return {
            ...position,
            position_x: position.position_x + displacement.x,
            position_y: position.position_y + displacement.y
          }
        } else {
          return position
        }
      })
    })
  },

  handlePositionChange(position) {
    this.props.dispatch(adminUpdatePostSpot(position));
  },

  render() {
    return (
      <div className='AdminMapEditSpots'>
        <MapContainer style={{ height: 600 }}>
          <Mouse
            onZoom={this.zoom}
            onPan={this.pan}
            onClick={this.click}
            onRelease={this.release}
            zoom={this.state.zoom}
          >
            <Touch
              onZoom={this.zoom}
              onPan={this.pan}
              onTap={this.tap}
              onRelease={this.release}
              zoom={this.state.zoom}
            >
              <Map
                {...this.props}
                {...this.state}
                onPositionGrab={(e, id) => {
                  this.setState({ grabbedPositionId: id })
                }}
                onPositionRelease={(e, id) => {
                  this.state.positions.map(position => {
                    if (position.id === id) {
                      this.handlePositionChange(position);
                    }
                  });
                  this.setState({ grabbedPositionId: null })
                }}
              />
            </Touch>
          </Mouse>
        </MapContainer>
      </div>
    )
  }
})

let mapStateToProps = state => {
  return {
    app: state.app
  }
}

export default connect(mapStateToProps)(Dimensions()(AdminMapEditSpots))

import { getFetchConfig, parseJSON, checkStatus } from '../../common/fetchHelper'
import Promise from 'bluebird'
if (!window.Promise) {
  window.Promise = Promise;
}

function fetchSuccess(branch) {
  return { type: 'FETCH_BRANCH_SUCCESS', data: branch }
}

function fetchFailure(errors) {
  return { type: 'FETCH_BRANCH_FAILURE', errors }
}

export function fetchBranches(id) {
  return (dispatch, getState) => {
    let url = `/api/v1/admin/branches/${id}.json`

    return fetch(url, getFetchConfig)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        dispatch(fetchSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
        throw error;
      })
  }
}

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import Barcode from 'react-barcode'
import ReactDOM from 'react-dom'
import {translate} from 'react-i18next'

import Map from '../../common/lib/map/Map'
import ZoomedMap from '../../common/lib/map/ZoomedMap'
import MapContainer from '../../common/lib/map/Container'
import CancelOrder from './CancelOrder'
import InformOrder from './InformOrder'
import ConfirmButton from '../../common/lib/ConfirmButton'
import Button from '../../common/lib/Button'
import Modal from '../../common/lib/Modal'
import { DEFAULT_BAR_FORMAT } from '../../common/variables'
import { operatorReorder,
         showDetails,
         carrierTakeOrder,
         carrierDeliverOrder,
         carrierCancelOrder,
         carrierInformOrder } from '../../common/orders/actions'

let OrderDetails = React.createClass({

  componentDidUpdate() {
    let scrollableDiv = ReactDOM.findDOMNode(this).parentNode;
    scrollableDiv.scrollTop = 0;
  },

  handleHideDetails() {
    this.props.dispatch(showDetails(null));
  },

  renderTakeButton() {
    if(!['taken', 'cancelled'].includes(this.props.order.status)) {
      return <ConfirmButton
        type="button"
        onClick={e => this.handleOrderTake(e)}
        color="blue"
      >
        {this.props.t('take')}
      </ConfirmButton>;
    }
  },

  handleOrderTake(e) {
    e.preventDefault();
    this.props.dispatch(carrierTakeOrder(this.props.order.id));
  },

  renderDeliveryButton() {
    if (this.props.order.carrier_id == this.props.currentUser){
      return  <ConfirmButton
        type="button"
        onClick={e => this.handleOrderDeliver(e)}
        color="blue"
      >
        {this.props.t('deliver')}
      </ConfirmButton>;
    }
  },

  handleOrderDeliver(e) {
    e.preventDefault();
    this.props.dispatch(carrierDeliverOrder(this.props.order.id));
  },

  renderCancelButton() {
    if (this.props.order.status != 'cancelled') {
      return <div>
        <Button
          type="button"
          onClick={e => this.refs.cancelModal.showModal()}
          color="blue"
        >
          {this.props.t('common:cancel')}
        </Button>
        <Modal refName='cancelModal' ref='cancelModal'>
          <CancelOrder orderIds={[this.props.order.id]}/>
        </Modal>
      </div>;
    }
  },

  renderInformButton() {
    if (
      (this.props.order.status == 'new' || this.props.order.status == 'taken') &&
      this.props.order.status != 'cancelled'
    ) {
    return <div>
      <Button
        color="blue"
        onClick={e => this.refs.infosModal.showModal()}
      >
        {this.props.t('orders:inform')}
      </Button>
      <Modal refName='infosModal' ref='infosModal'>
        <InformOrder orderIds={[this.props.order.id]}/>
      </Modal>
    </div>;
    }
  },

  renderRemarks() {
    if (this.props.order.remarks) {
      return (
        <div className="order-more-remarks">
          <b>{this.props.t('remarks')}</b>
          {this.props.order.remarks}
        </div>
      )
    }

    return null;
  },

  handleOrderInform(e) {
    e.preventDefault();
    this.props.dispatch(carrierInformOrder(this.props.order.id));

  },

  render() {
    let { order, t } = this.props

    return (
      <div className="order-container order-more">
        <div className="order-more-desc">
          {order.item.description}
        </div>
        {order.item.stock_quantity == -1
          ? ''
          : (
            <div className="smaller">
              {t("supplies:stock_quantity")}: {order.item.stock_quantity}
            </div>
          )
        }
        <div className="order-actions">
          {this.renderCancelButton()}
          {this.renderDeliveryButton()}
          {this.renderTakeButton()}
          {this.renderInformButton()}
        </div>
        {order.item.image_medium_url &&
          <div className="center">
            <img src={order.item.image_medium_url} className="order-image" />
          </div>
        }
        {this.renderRemarks()}
        <div className="order-more-map mt30">
          <MapContainer style={{ height: 300 }}>
            <ZoomedMap
              image={{
                url: order.location.map_url,
                width: order.location.map_width,
                height: order.location.map_height
              }}
              positions={[order.spot]}
              selectedPositionId={order.spot.id}
              zoomId={order.id}
            />
          </MapContainer>
        </div>
        <div className="order-more-details mt30">
          <div className="order-more-location-barcode">
            <div className="location-barcode-title">
              {t('locationCode')}
            </div>
            <div className="location-barcode-code">
              <Barcode
                value={order.location.symbol}
                format={DEFAULT_BAR_FORMAT}
              />
            </div>
          </div>

          <div className="order-more-item-barcode">
            <div className="item-title-code">
              <div className="item-barcode-title">
                {t('productCode')}
              </div>
              <div className="item-barcode-code">
                <Barcode value={order.item.code} format={DEFAULT_BAR_FORMAT} width={1} fontSize={20} />
              </div>
            </div>
          </div>
      </div>
      </div>
    )
  }

});

function mapStateToProps(state, ownProps) {
  return {
    order: state.orders.orders.filter(function(elem) {
      return elem.id == state.orders.showDetailsOrderId;
    })[0],
    currentUser: state.app.currentUserId
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrderDetails))

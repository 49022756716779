import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import moment from 'moment/moment.js'
import momentEN from 'moment/locale/en-gb'
import momentPl from 'moment/locale/pl'
import momentCS from 'moment/locale/cs'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import { ORDERS_COUNT_LIMIT } from '../../common/variables'

import { operatorFetch as fetchOrders } from '../../common/orders/actions'
import Order from './Order'

let OrdersList = React.createClass({

  componentDidMount() {
    if (this.props.orders.length === 0) {
      this.props.dispatch(fetchOrders());
    }
  },

  operatorsOrders() {
    return this.props.orders.filter((order) => {
      return order.operator_id == this.props.operatorId || order.operator_id == '';
    });
  },

  filteredOrders() {
    if (this.props.searchText == '') {
      return this.operatorsOrders();
    }

    return this.operatorsOrders().filter((order) => {
      return order.number.includes(this.props.searchText) ||
        order.code.includes(this.props.searchText) ||
        order.item.description.toLowerCase().includes(this.props.searchText.toLowerCase());
    });
  },

  sortedOrders() {
    return this.filteredOrders().sort((a,b) => {
      return moment.utc(b.created_at).diff(moment.utc(a.created_at));
    }).slice(0, ORDERS_COUNT_LIMIT);
  },

  render() {
    return (
      <table className="operator-orders" id="orders-list">
        <ReactCSSTransitionGroup
          component="tbody"
          transitionName="fade"
          transitionAppear={true}
          transitionAppearTimeout={2000}
          transitionEnterTimeout={3000}
          transitionLeaveTimeout={2500}
        >
          {this.sortedOrders().map(function(order, index) {
            return <Order
              key={order.id}
              index={index}
              updated_at={order.updated_at}
              order={order}
            />;
          })}
        </ReactCSSTransitionGroup>
      </table>
    )
  }

})

let mapStateToProps = state => {
  return {
    orders: state.orders.orders,
    operatorId: state.app.currentUserId
  }
}

export default connect(mapStateToProps)(OrdersList)

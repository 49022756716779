import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

let Profile = React.createClass({
  getInitialState: function() {
    return {  }
  },

  render() {
    let { CSRFToken, user, t } = this.props

    return (
      <div className="content-wrapper">
        <div className="page-header" style={{ backgroundColor: 'inherit', color: 'var(--text-on-background-color)' }}>
          {t('title')}
        </div>
        <div className="mt20">
          <form id="order-form" action="/api/v1/profile" acceptCharset="UTF-8" method="post">
            <input type="hidden" name="_method" value="put" />
            <input type="hidden" name="authenticity_token" value={CSRFToken} />

            <div className="row">
              <div className="col-4">
                <div className="input string optional">
                  <label className="string optional">*{t('firstName')}</label>
                  <input id="user_first_name" name="user[first_name]" className="string optional" type="text" defaultValue={user.first_name}></input>
                </div>

                <div className="input string optional">
                  <label className="string optional">*{t('lastName')}</label>
                  <input id="user_last_name" name="user[last_name]" className="string optional" type="text" defaultValue={user.last_name}></input>
                </div>
              </div>

              <div className="col-4">
                <div className="input string optional disabled" disabled>
                  <label className="string optional disabled">{t('login')}</label>
                  <input
                    id="user_login"
                    name="user[login]"
                    className="string optional disabled"
                    type="text"
                    defaultValue={user.username}
                    disabled="disabled"
                  ></input>
                </div>

                <div className="input string optional" disabled>
                  <label className="string optional">{t('email')}</label>
                  <input
                    id="user_email"
                    name="user[email]"
                    className="string optional"
                    type="text"
                    defaultValue={user.email}
                    disabled="disabled"
                  ></input>
                </div>
              </div>

              <div className="col-4">
                <div className="input string optional disabled">
                  <label className="string optional disabled">{t('password')}</label>
                  <input id="user_password" name="user[password]" className="string optional" type="password" defaultValue={''}></input>
                </div>

                <div className="input string optional">
                  <label className="string optional">{t('passwordConfirmation')}</label>
                  <input id="user_password_confirmation" name="user[password_confirmation]" className="string optional" type="password" defaultValue={''}></input>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                type="submit"
                name="commit"
                value={this.props.t('common:save')}
                className='btn btn-nephritis'
              />
            </div>
          </form>
        </div>
      </div>
    )
  }

})

let mapStateToProps = state => {
  return {
    user: state.app.user,
    CSRFToken: state.app.CSRFToken
  }
}

export default connect(mapStateToProps)(translate(['profile'])(Profile))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {translate} from 'react-i18next'

import Button from '../../common/lib/Button'

let SpecialOrder = React.createClass({

  render() {
    let { specialOrder, currentLocationId, t } = this.props;

    return  (
      <div className="supply-location">
        <div className={"searchable-supply supply-container supply-" + (this.props.index % 2 == 0 ? 'even' : 'odd')}>
          <div className="supply-box">
            <div className="supply-meta">
              <span className="supply-description">{specialOrder.name}</span>
            </div>
            <div className="supply-quantity">
              <Link
                to={`/orders/special_summary/${currentLocationId}/${specialOrder.id}`}
                className={`btn btn-success`}
              >
                <i className="fa fa-check"></i>
                {t('order')}
              </Link>
            </div>
           </div>
         </div>
       </div>
    )
  }

});

export default connect()(translate(['common'])(SpecialOrder))

import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

let Header = React.createClass({

  filteredAvailableLocales() {
    return this.props.app.availableLocales.filter((lang) => {
      return lang !== this.props.app.language;
    });
  },

  render() {
    const { t } = this.props;

    return (
      <header id="portable-header">
        <div className="content-wrapper">
          <div className="header-title operator-header-title">
            <span>
              <div className="fa fa-barcode"></div>
              {" "}{t('scanner:title')}
            </span>
          </div>
          <ul className="actions">
            <li className="user-menu">
              <a style={{ userSelect: 'none' }}>
                <i className="fa fa-flag"></i>&nbsp;
                <span className='user-name'>{this.props.app.language.toUpperCase()}</span>
              </a>
              <ul>
                {this.filteredAvailableLocales().map((lang) => {
                  return (
                    <li key={lang}>
                      <a href={`/locales/${lang}/set_current`}>{lang.toUpperCase()}</a>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className="user-menu">
              <a href="/users/sign_in">
                <i className="fa fa-arrow-left"></i>&nbsp;
                <span className='user-name'>{t('common:back')}</span>
              </a>
            </li>
          </ul>
        </div>
      </header>
    )
  }

})

let mapStateToProps = (state) => {
  return {
    app: state.app
  }
}

export default connect(mapStateToProps)(translate(['header'])(Header))

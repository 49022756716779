import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import ActionCable from 'actioncable';
import bowser from 'bowser';

import { Router, Route, IndexRoute, Redirect,
         browserHistory, IndexRedirect } from 'react-router'
import { createHistory, useBasename } from 'history'

import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './i18n'

import AppReducer from './common/app/reducer'
import OrdersReducer from './common/orders/reducer'
import SuppliesReducer from './common/supplies/reducer'
import LocationsReducer from './common/locations/reducer'

import Header from './operator/app/Header';
import App from './operator/app/App';

import OrdersWrapper from './operator/orders/OrdersWrapper'
import OrderSummary from './operator/orders/OrderSummary'
import SpecialOrderSummary from './operator/orders/SpecialOrderSummary'
import SuppliesList from './operator/supplies/SuppliesList'

import { setCSRFToken, setCurrentPlant, setCurrentUser,
         setLanguage, setUserName, setUser } from './common/app/actions'
import { update as updateOrder, setCancelReasons as setOrderCancelReasons} from './common/orders/actions'
import Profile from './common/pages/Profile';
import ThemeProvider from './common/lib/ThemeProvider';
import { updateSupplyItemStockQuantity } from './common/supplies/actions';

require('./assets/stylesheets/application.sass');

let reducers = combineReducers({
  orders: OrdersReducer,
  supplies: SuppliesReducer,
  locations: LocationsReducer,
  app: AppReducer
});

let store = createStore(reducers, compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  ));

let consumer = ActionCable.createConsumer();
let connection = consumer.subscriptions.create('PlantOrdersChannel', {
  connected() {
    this.perform('follow', {
      plant_id: document.getElementsByName('current_plant_id')[0].dataset.id
    });
  },
  disconnected() {},
  received(order) {
    store.dispatch(updateOrder(order['order']));
    if (order.order?.supply?.item) {
      store.dispatch(updateSupplyItemStockQuantity(order.order.supply.id, order.order.supply.item.stock_quantity))
    }
  }
})

let i18nObj = initializeI18n(document.getElementsByName('language')[0].dataset.lang);
store.dispatch(setCSRFToken(document.getElementsByName('csrf-token')[0].content));
store.dispatch(setCurrentPlant(document.getElementsByName('current_plant_id')[0].dataset.id));
store.dispatch(setLanguage(document.getElementsByName('language')[0].dataset.lang));
store.dispatch(setUserName(document.getElementsByName('user_name')[0].dataset.name));
store.dispatch(setUser(JSON.parse(document.getElementsByName('user')[0].dataset.user)));
store.dispatch(setCurrentUser(document.getElementsByName('current_user_id')[0].dataset.id));
store.dispatch(setOrderCancelReasons(JSON.parse(document.getElementsByName('cancel_reasons')[0].dataset.elems)));

const history = useBasename(createHistory)({
  basename: "/operator/app"
})

// if (!bowser.msie) {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={ i18nObj }>
        <ThemeProvider>
          <Router history={history}>
            <Route path ="/" component={App}>
              <IndexRedirect to="supplies" />

              <Route path="orders" component={OrdersWrapper} />
              <Route path="orders/summary/:direction" component={OrderSummary} />
              <Route path="orders/special_summary/:location/:id" component={SpecialOrderSummary} />

              <Route path="supplies" component={SuppliesList} />

              <Route path="profile" component={Profile} />
              <Redirect from='*' to='supplies' />
            </Route>
          </Router>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>,
    document.getElementById('operator-app')
  )
// } else {
//   let el = document.getElementsByClassName('unsupported_browser')[0];
//   el.setAttribute("style", "display:block;");
// }

import React, { PropTypes } from 'react'
import {connect} from 'react-redux'

import OrdersWrapper from '../orders/OrdersWrapper'
import Header from './Header'


const App = React.createClass({
  render() {
    return (
      <div>
        <Header/>
        <section className="content">
          <OrdersWrapper />
        </section>
      </div>
    )
  }
})

let mapStateToProps = state => {
  return {
    app: state.app
  }
}

export default connect(mapStateToProps)(App)

/**
 * Changes pan by specified values.
 * Arguments: Map props, pan
 * Retunrs: Map props
 *
 * e.g.: Given current pan { x: 100, y: 50 } and new pan { x: -20, y: 0 },
 *       the result will be { x: 80, y: 50 }
 */
export default function pan({ pan }, newPan) {
  return {
    pan: {
      x: pan.x - newPan.x,
      y: pan.y - newPan.y
    }
  }
}

import React, { PropTypes } from 'react'
import Color from 'color'

import { Nephritis, Asbestos } from '../colors'

const Position = React.createClass({
  truncateText: 16,

  propTypes: {
    position_x: PropTypes.number.isRequired,
    position_y: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    size: PropTypes.number,
    isSelected: PropTypes.bool,
    zoom: PropTypes.number,
    onClick: PropTypes.func,
    onGrab: PropTypes.func,
    onRelease: PropTypes.func,
    animate: PropTypes.bool
  },

  getDefaultProps() {
    return {
      size: 50,
      onClick: e => {},
      onGrab: e => {},
      onRelease: e => {},
      zoom: 1
    }
  },

  getInitialState() {
    return {
      isHighlighted: false
    }
  },

  getColor() {
    let { isSelected, isGrabbed } = this.props
    let { isHighlighted } = this.state
    let color = Color(isSelected || isHighlighted || isGrabbed ? Nephritis : Asbestos)

    return color.rgbString()
  },

  getFillColor() {
    let color = Color(this.getColor())

    if (this.state.isHighlighted) {
      color.lighten(0.2)
    }

    return color.rgbString()
  },

  getTextColor() {
    return Color(this.getColor()).darken(0.8).rgbString()
  },

  getSize() {
    return this.props.size / this.props.zoom
  },

  getText() {
    let { symbol } = this.props
    return symbol.substr(0, this.truncateText) + (symbol.length > this.truncateText ? '...' : '')
  },

  getTransform() {
    let { position_x, position_y, zoom } = this.props
    return `translate(${position_x * zoom}px, ${position_y * zoom}px)`
  },

  grab(e) {
    this.moved = false
    this.props.onGrab(e, this.props.id)
    this.setState({ isHighlighted: true })
  },

  release(e) {
    if (this.moved !== true) {
      this.props.onClick(e, this.props.id)
    }
    this.props.onRelease(e, this.props.id)
    this.setState({ isHighlighted: false })
  },

  render() {
    return (
      <g
        onClick={e => e.preventDefault() }

        onMouseEnter={e => this.setState({ isHighlighted: true })}
        onMouseLeave={e => this.setState({ isHighlighted: false })}
        onMouseDown={this.grab}
        onMouseMove={e => this.moved = true}
        onMouseUp={this.release}

        onTouchStart={this.grab}
        onTouchMove={e => this.moved = true}
        onTouchEnd={this.release}
        onTouchCancel={this.release}

        style={{
          transform: this.getTransform(),
          transition: this.props.animate === true ? 'all 1s linear' : null,
          cursor: 'pointer'
        }}
      >
        <rect
          x={-this.props.size / 2}
          y={-this.props.size / 2}
          width={this.props.size}
          height={this.props.size}
          fill={this.getFillColor()}
          stroke="#fff"
          strokeWidth={this.props.size / 10}
        />

        <g
          textAnchor="middle"
          fontSize={this.props.size * 0.75}
          transform={`translate(0, ${this.props.size * 1.25})`}
        >
          <text stroke="#fff" strokeWidth={this.props.size / 5}>{this.getText()}</text>
          <text>{this.getText()}</text>
        </g>
      </g>
    )
  }
})

export default Position

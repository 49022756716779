import React, {PropTypes} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import Spinner from '../../common/lib/Spinner'
import Map from '../../common/lib/map/Map'
import ZoomedMap from '../../common/lib/map/ZoomedMap'
import MapContainer from '../../common/lib/map/Container'
import { changeRemarks, operatorPlaceOrder as placeSuppliesOrder } from '../../common/orders/actions'

let OrderSummary = React.createClass({

  getInitialState: function() {
    return {
        selectedSpot: null
    }
  },

  componentWillMount() {
    if (this.props.location !== undefined) {
      this.setState({selectedSpot: this.props.location.spots[0].id});
    } else {
      // redirect to root
      this.props.router.push('/');
    }
  },

  actions() {
    if (!this.props.isSendingOrders){
      return (
        <div className="finalize-order-actions">
          <input
            type="submit"
            name="commit"
            value={this.props.t('common:order')}
            className='btn btn-nephritis'
            onClick={this.finishClick}>
          </input>
          <a
            className="btn btn-alizarin"
            href="#"
            onClick={this.cancelClick}>
            {this.props.t('common:cancel')}
          </a>
        </div>
      );
    } else {
      return (
        <div className="finalize-order-actions">
          <Spinner show={true} />
        </div>);
    }
  },

  changeSpotClick(event) {
    this.setState({selectedSpot: event.target.value});
  },

  handleChangeRemarks(event) {
    this.props.dispatch(changeRemarks(event.target.value));
  },

  cancelClick(e) {
    e.preventDefault();

    this.props.router.push('/');
  },

  finishClick() {
    let spotId = this.state.selectedSpot;
    this.props.dispatch(placeSuppliesOrder(
      this.props.supplies,
      spotId,
      this.props.params.direction,
      this.props.remarks
    ));
    this.props.dispatch(changeRemarks(''));
    this.props.router.push('orders');
  },

  render() {
    const { t, location } = this.props;

    if (location === undefined) { return null }

    return (
      <div className="content-wrapper">
        <div id="order-summary">
          <div className="finalize-order">
            {t('deliveryLocation')}
            <span id="order-location-symbol">{this.props.location.symbol}</span>
            {this.actions()}
          </div>
          <div className="order-spots">
            <div className="order-map" id="map-container">
              <MapContainer style={{ height: 450 }}>
                <ZoomedMap
                  image={{
                    url: this.props.location.map_url,
                    width: this.props.location.map_width,
                    height: this.props.location.map_height
                  }}
                  positions={this.props.location.spots}
                  selectedPositionId={this.state.selectedSpot}
                  onPositionClick={(e, id) => {
                    this.setState({ selectedSpot: id })
                  }}
                />
              </MapContainer>
            </div>
          </div>
          <div className="order-details">
            <div className="order-details-location">
              <table>
                <tbody>
                  <tr>
                    <th colSpan="2">{t('remarks')}</th>
                    <td className="order-remarks-cell">
                      <input
                        className="order-remarks-input"
                        type="text"
                        name="remarks"
                        value={this.props.remarks}
                        onChange={this.handleChangeRemarks}
                        placeholder={t('remarksPlaceholder')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="2">{t('orderedProducts')}</th>
                  </tr>
                  {this.props.supplies.map((supply) => {
                    return <tr key={supply.id}>
                      <td className="qty-col">{supply.count}
                        x {parseInt(supply.batch_quantity)}
                        {supply.item.unit}.
                      </td>
                      <td>
                        {supply.item.image_thumb_url && (
                          <div className="order-thumb">
                            <img
                              src={supply.item.image_thumb_url}
                              className="order-image"
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        {supply.item.code}
                        - {supply.item.description}
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

});

let mapStateToProps = (state) => {
  return {
    supplies: state.supplies.supplies.filter(supply => {
      return (supply.count != 0 && supply.count != null);
    }),
    location: state.locations.locations.filter(location => {
      return location.id == state.locations.selectedId;
    })[0],
    isSendingOrders: state.orders.isSendingOrders,
    remarks: state.orders.remarks
  }
}

export default connect(mapStateToProps)(translate(['orders'])(OrderSummary))

export default (
  state = {
    CSRFToken: null,
    currentView: 'supplies',
    currentUserId: null,
    language: 'pl',
    userName: '',
    displayName: '',
    locationIds: [],
    theme: 'dark'
  },
  action
) => {
  switch (action.type) {
    case 'SET_APP_CSRF_TOKEN':
      return {
        ...state,
        CSRFToken: action.CSRFToken
      }

    case 'SET_CURRENT_VIEW':
      return {
        ...state,
        currentView: action.data
      }

    case 'SET_CURRENT_PLANT':
      return {
        ...state,
        plant_id: action.data
      }

    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUserId: action.data
      }

    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.data
      }

    case 'SET_USER_NAME':
      return {
        ...state,
        userName: action.data
      }

    case 'SET_USER':
      return {
        ...state,
        user: action.data
      }

    case 'SET_DISPLAY_NAME':
      return {
        ...state,
        displayName: action.data
      }

    case 'SET_AVAILABLE_LOCALES':
      return {
        ...state,
        availableLocales: JSON.parse(action.data)
      }

    case 'SET_USER_LOCATIONS':
      return {
        ...state,
        locationIds: JSON.parse(action.data)
      }

    case 'SET_THEME':
      return {
        ...state,
        theme: action.data
      }

    default:
      return state
  }
}
